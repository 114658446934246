import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
const axios = require('axios');
const Addnewaddress = () => {


    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [province, setProvince] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [town, setTown] = useState([]);
    const { user } = useSelector((state) => ({ ...state }));
    const [cityv, setCityv] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                let jsoncity = await axios.get('http://admin.gemgolf.online/ajax/deliveryjson?district=' + province);  
                setCity(jsoncity.data);
            } catch (error) {
                console.error(error.message);
            }
        }
        fetchData();
    }, [province]);

    useEffect(() => {
        const fetchData2 = async () => {
            try {
                let jsoncity2 = await axios.get('http://admin.gemgolf.online/ajax/deliveryjson?city=' + district);
                setTown(jsoncity2.data);
            } catch (error) {
                console.error(error.message);
            }
        }
        fetchData2();
    }, [district]);

    const options = [
        { value: 'Central', label: 'Central' },
        { value: 'North Central', label: 'North Central' },
        { value: 'North Eastern', label: 'North Eastern' },
        { value: 'North Western', label: 'North Western' },
        { value: 'Sabaragamuwa', label: 'Sabaragamuwa' },
        { value: 'Southern', label: 'Southern' },
        { value: 'Uva', label: 'Uva' },
        { value: 'Western', label: 'Western' }
    ]

    let handleCityChange = async (e) => {
        setProvince(e.target.value)
    }

    const handleSubmit = (e) => {
        if (name == '') {
            toast.error("please fill all required fields", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        else if (phone == '') {
            toast.error("please fill all required fields", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        else if (province == '') {
            toast.error("please fill all required fields", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        else if (district == '') {
            toast.error("please fill all required fields", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        else {
            axios.post("http://admin.gemgolf.online/ajax/add_address.php", {
                email: user.email,
                name: name,
                phone: phone,
                province: province,
                district: district,
                city: city,
                address: address
            })
                .then((response) => {
                    toast.success("success", {
                        position: toast.POSITION.TOP_LEFT
                    });
                });
        }
    }

    return (
        <>

            <section className="page-content container mt-3">
                <div className="" id="content">
                    <div className="row">
                    <Profilenav />

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">Add New Address</h3>
                                </div>
                                <div className="card-body">
                                    <form className="customized-form">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Full name</label>
                                                        <input type="text" className="form-control" name={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your first and last name" />
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <label for="">Phone Number</label>
                                                        <input type="tel" className="form-control" name={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Please enter your phone number" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-row">
                                                    <div className="form-group col-12 hide-row">
                                                        <label for="">Province</label>

                                                        <select className="custom-select" onChange={handleCityChange} id="province" >
                                                            <option selected>Please choose your province</option>
                                                            <option value="Central">Central</option>
                                                            <option value="Eastern">Eastern</option>
                                                            <option value="North Central3">North Central</option>
                                                            <option value="Northern">Northern</option>
                                                            <option value="North Western">North Western</option>
                                                            <option value="Sabaragamuwa">Sabaragamuwa</option>
                                                            <option value="Southern">Southern</option>
                                                            <option value="Uva">Uva</option>
                                                            <option value="Western">Western</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-12 hide-row">
                                                        <label for="">District</label>
                                                        <select className="custom-select" id="district" onChange={(e) => setDistrict(e.target.value)} >
                                                            <option value="">select</option>

                                                            {city != '' ? city.map((value, i) =>
                                                                <option key={value}>{value}</option>
                                                            ) : ''}

                                                        </select>
                                                    </div>

                                                    <div className="form-group col-12 hide-row">
                                                        <label for="">City</label>
                                                        <select className="custom-select" onChange={(e) => setCityv(e.target.value)} id="city">
                                                            <option value="">select</option>

                                                            {town.map((value, i) =>
                                                                <option key={value}>{value}</option>
                                                            )}

                                                        </select>
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <label for="">Address</label>
                                                        <input type="text" className="form-control" onChange={(e) => setAddress(e.target.value)}
                                                            value={address} placeholder="For Example: House# 123, Street# 123, ABC Road" />
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <label for="">Select a label for effective delivery:</label><br />
                                                        <div class="wrapper py-2">
                                                            <input type="radio" name="select" id="option-1" checked />
                                                            <input type="radio" name="select" id="option-2" />
                                                            <label for="option-1" class="option option-1">
                                                                <span><i class="fas fa-home pr-2"></i> Home</span>
                                                            </label>
                                                            <label for="option-2" class="option option-2">
                                                                <span><i class="fas fa-building pr-2"></i> Office</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                            <label class="form-check-label" for="defaultCheck1">
                                                                Make default billing address
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                                            <label class="form-check-label" for="defaultCheck2">
                                                                Make default shipping address
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-6">
                                                        <a href="/profile">
                                                            <button type="button" className="button-size-md btn btn-dark">CANCEL</button>
                                                        </a>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <button type="button" className="product-button button-size-md button-lightblue" onClick={handleSubmit}>SAVE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Addnewaddress