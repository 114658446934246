import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';

const Cancellationdetails = () => {
    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">

                        <Profilenav />
                        {/* <button id="sidebarCollapse" type="button" className="btn btn-light bg-white rounded-pill shadow-sm mb-4"><i className="fa fa-bars"></i></button> */}

                        <div className="col-12 col-md-8">
                            <h2 class="my-3">Cancellation Details</h2>
                            <div class="card card-2 p-3 mb-3">
                                <div class="card-header bg-white border-0">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12">
                                            <p class="mb-0">Canceled on 2021-12-16 16:56:06</p>
                                            <small>Order <a class="text-info" href="/orderdetails">#204470513477147 </a></small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card card-2 p-3 mb-5">
                                <div className="card border-0">
                                    <div className="card-body">
                                        <div className="row mb-3 progress-modal">
                                            <div className="col-12">
                                                <ul class="step d-flex flex-nowrap text-center">
                                                    <li class="step-item">
                                                        <a class="text-center mt-3">Cancellation in Progress</a>
                                                    </li>
                                                    <li class="step-item active">
                                                        <a class="text-center mt-3">Cancelled</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row my-3 justify-content-center">
                                            <div className="col-md-9 col-sm-10">
                                                <div className="panel-details">
                                                    <ul>
                                                        <li><span>2021-12-12 16:10:10</span>An item(s) from your order has been cancelled.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-12 col-sm-3 col-lg-2 col-xl-2">
                                                <img src="https://onme-dev.s3.ap-southeast-1.amazonaws.com/ET_0368.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZX25VTEUVQXRDQ7H%2F20220525%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20220525T000008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=518400&X-Amz-Signature=59e6f5e9b3e23b7d4fc4d84b3af166474c9a693acf4763d08f483167d9e05b4b" className="img-fluid" />
                                            </div>
                                            <div className="col-12 col-sm-9 col-lg-5 col-xl-5">
                                                <p className="mt-2 mt-sm-0 product-card-title">Smart 20 LED Solar Lights Outdoor, Waterproof Solar Motion Sensor Light</p>
                                            </div>
                                            <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-3 mt-2 mt-lg-0">
                                                <span className="badge badge-pill badge-light mr-2">Rs. 194</span>
                                            </div>
                                            <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                <span className="text-gray">Qty: </span><span className="text-dark">1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Cancellationdetails