import React, { useEffect, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { currentUser } from "./functions/auth";
import { LoadingOutlined } from "@ant-design/icons";
import './main.css';
import Checkoutprocess from "./pages/Checkoutprocess";
import Collection from "./pages/Collection";
import Profile from "./pages/Profile";
import Addressbook from "./pages/Addressbook";
import Ordercomplete from "./pages/Ordercomplete";
import Addnewaddress from "./pages/Addnewaddress";
import Editaddress from "./pages/Editaddress";
import Reviews from "./pages/Reviews";
import Addreviews from "./pages/Addreviews";
import Profilenav from "./pages/Profilenav";
import Myreturns from "./pages/Myreturns";
import Mycancellations from "./pages/Mycancellations";
import Mywishlist from "./pages/Mywishlist";
import Requestcancellation from "./pages/Requestcancellation";
import Cancellationresult from "./pages/Cancellationresult";
import Myorders from "./pages/Myorders";
import faq from "./pages/static/faq";
import Orderdetails from "./pages/Orderdetails";
import Cancellationdetails from "./pages/Cancellationdetails";
import { Spin } from 'antd';
import requestReturn from "./pages/requestReturn";

// import Login from "./pages/auth/Login";
// import Register from "./pages/auth/Register";
// import Home from "./pages/Home";
// import Header from "./components/nav/Header";
// import SideDrawer from "./components/drawer/SideDrawer";
// import RegisterComplete from "./pages/auth/RegisterComplete";
// import ForgotPassword from "./pages/auth/ForgotPassword";
// import History from "./pages/user/History";
// import UserRoute from "./components/routes/UserRoute";
// import AdminRoute from "./components/routes/AdminRoute";
// import Password from "./pages/user/Password";
// import Wishlist from "./pages/user/Wishlist";
// import AdminDashboard from "./pages/admin/AdminDashboard";
// import CategoryCreate from "./pages/admin/category/CategoryCreate";
// import CategoryUpdate from "./pages/admin/category/CategoryUpdate";
// import SubCreate from "./pages/admin/sub/SubCreate";
// import SubUpdate from "./pages/admin/sub/SubUpdate";
// import ProductCreate from "./pages/admin/product/ProductCreate";
// import AllProducts from "./pages/admin/product/AllProducts";
// import ProductUpdate from "./pages/admin/product/ProductUpdate";
// import Product from "./pages/Product";
// import CategoryHome from "./pages/category/CategoryHome";
// import SubHome from "./pages/sub/SubHome";
// import Shop from "./pages/Shop";
// import Cart from "./pages/Cart";
// import Checkout from "./pages/Checkout";
// import CreateCouponPage from "./pages/admin/coupon/CreateCouponPage";
// import Payment from "./pages/Payment";


// using lazy
const Login = lazy(() =>
    import("./pages/auth/Login"));
const Register = lazy(() =>
    import("./pages/auth/Register"));
const Home = lazy(() =>
    import("./pages/Home"));
const Header = lazy(() =>
    import("./components/nav/Header"));
const SideDrawer = lazy(() =>
    import("./components/drawer/SideDrawer"));
const RegisterComplete = lazy(() =>
    import("./pages/auth/RegisterComplete"));
const ForgotPassword = lazy(() =>
    import("./pages/auth/ForgotPassword"));
const History = lazy(() =>
    import("./pages/user/History"));
const UserRoute = lazy(() =>
    import("./components/routes/UserRoute"));
const AdminRoute = lazy(() =>
    import("./components/routes/AdminRoute"));
const Password = lazy(() =>
    import("./pages/user/Password"));
const Wishlist = lazy(() =>
    import("./pages/user/Wishlist"));
const AdminDashboard = lazy(() =>
    import("./pages/admin/AdminDashboard"));
const CategoryCreate = lazy(() =>
    import("./pages/admin/category/CategoryCreate")
);
const CategoryUpdate = lazy(() =>
    import("./pages/admin/category/CategoryUpdate")
);
const SubCreate = lazy(() =>
    import("./pages/admin/sub/SubCreate"));
const SubUpdate = lazy(() =>
    import("./pages/admin/sub/SubUpdate"));
const ProductCreate = lazy(() =>
    import("./pages/admin/product/ProductCreate"));
const AllProducts = lazy(() =>
    import("./pages/admin/product/AllProducts"));
const ProductUpdate = lazy(() =>
    import("./pages/admin/product/ProductUpdate"));
const Product = lazy(() =>
    import("./pages/Product"));
const CategoryHome = lazy(() =>
    import("./pages/category/CategoryHome"));
const SubHome = lazy(() =>
    import("./pages/sub/SubHome"));
const Shop = lazy(() =>
    import("./pages/Shop"));
const Cart = lazy(() =>
    import("./pages/Cart"));
const Checkout = lazy(() =>
    import("./pages/Checkout"));
const Order = lazy(() =>
    import("./pages/Order"));
const Checkoutguest = lazy(() =>
    import("./pages/Checkoutguest"));
const Faq = lazy(() =>
    import("./pages/static/faq"));
const About = lazy(() =>
    import("./pages/static/about"));
const Contact = lazy(() =>
    import("./pages/static/contact"));
const Privacy = lazy(() =>
    import("./pages/static/privacy"));
const Terms = lazy(() =>
    import("./pages/static/terms"));
const CreateCouponPage = lazy(() =>
    import("./pages/admin/coupon/CreateCouponPage")
);
const Payment = lazy(() =>
    import("./pages/Payment"));

const App = () => {
    const dispatch = useDispatch();

    // to check firebase auth state
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                // console.log("user", user);

                currentUser(idTokenResult.token)
                    .then((res) => {
                        dispatch({
                            type: "LOGGED_IN_USER",
                            payload: {
                                name: res.data.name,
                                email: res.data.email,
                                token: idTokenResult.token,
                                role: res.data.role,
                                _id: res.data._id,
                            },
                        });
                    })
                    .catch((err) => console.log(err));
            }
        });
        // cleanup
        return () => unsubscribe();
    }, [dispatch]);

    return (
        <Suspense fallback={<div className="col text-center p-5" >
            <LoadingOutlined style={{ fontSize: 28, color: '#e0493e' }} spin /></div>
        }
        >
            <Header />

            <ToastContainer />
            <Switch >
                <Route exact path="/"
                    component={Home}
                />
                <Route exact path="/login"
                    component={Login}
                />
                <Route exact path="/register"
                    component={Register}
                />
                <Route exact path="/register/complete"
                    component={RegisterComplete}
                />
                <Route exact path="/forgot/password"
                    component={ForgotPassword}
                />
                <UserRoute exact path="/user/history"
                    component={History}
                />
                <UserRoute exact path="/user/password"
                    component={Password}
                />
                < UserRoute exact path="/user/wishlist"
                    component={Wishlist}
                />
                <AdminRoute exact path="/admin/dashboard"
                    component={AdminDashboard}
                />
                <AdminRoute exact path="/admin/category"
                    component={CategoryCreate}
                />
                <AdminRoute exact path="/admin/category/:slug"
                    component={CategoryUpdate}
                />
                <AdminRoute exact path="/admin/sub"
                    component={SubCreate}
                />
                <AdminRoute exact path="/admin/sub/:slug"
                    component={SubUpdate}
                />
                <AdminRoute exact path="/admin/product"
                    component={ProductCreate}
                />
                <AdminRoute exact path="/admin/products"
                    component={AllProducts}
                />
                <AdminRoute exact path="/admin/product/:slug"
                    component={ProductUpdate}
                />

                <Route exact path="/about"
                    component={About}
                />
                <Route exact path="/faq"
                    component={faq}
                />
                <Route exact path="/terms"
                    component={Terms}
                />
                <Route exact path="/privacy"
                    component={Privacy}
                />
                <Route exact path="/contact"
                    component={Contact}
                />

                <Route exact path="/product/:slug"
                    component={Product}
                />
                <Route exact path="/category/:slug"
                    component={CategoryHome}
                />
                <Route exact path="/sub/:slug"
                    component={SubHome}
                />
                <Route exact path="/shop"
                    component={Shop}
                />
                <Route exact path="/checkoutprocess"
                    component={Checkoutprocess}
                />
                <Route exact path="/collection"
                    component={Collection}
                />
                <Route exact path="/profile"
                    component={Profile}
                />
                <Route exact path="/addressbook"
                    component={Addressbook}
                />
                <Route exact path="/ordercomplete"
                    component={Ordercomplete}
                />
                <Route exact path="/addnewaddress"
                    component={Addnewaddress}
                />
                <Route exact path="/editaddress"
                    component={Editaddress}
                />
                <Route exact path="/reviews"
                    component={Reviews}
                />
                <Route exact path="/addreviews"
                    component={Addreviews}
                />
                <Route exact path="/requestcancellation"
                    component={Requestcancellation}
                />
                < Route exact path="/requestreturn"
                    component={requestReturn}
                />
                <Route exact path="/cancellationresult"
                    component={Cancellationresult}
                />
                <Route exact path="/myreturns"
                    component={Myreturns}
                />
                <Route exact path="/mycancellations"
                    component={Mycancellations}
                />
                <Route exact path="/myorders"
                    component={Myorders}
                />
                <Route exact path="/orderdetails"
                    component={Orderdetails}
                />
                <Route exact path="/cancellationdetails"
                    component={Cancellationdetails}
                />
                <Route exact path="/mywishlist"
                    component={Mywishlist}
                />
                <Route exact path="/profilenav"
                    component={Profilenav}
                />
                <Route exact path="/cart"
                    component={Cart}
                />
                < UserRoute exact path="/checkout"
                    component={Checkout}
                />
                <AdminRoute exact path="/admin/coupon"
                    component={CreateCouponPage}
                />
                <UserRoute exact path="/payment"
                    component={Payment}
                /> </Switch> </Suspense>
    );
};

export default App;