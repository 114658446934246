import React from 'react'
import FooterItem from '../../components/FooterItem';
import './basic_pages.css';
const faq = () => {

    return (
        <>

            <section className="bg-light faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 order-md-last">
                            <img className="img-fluid" src="images/matt-aylward-Nmh-pEBRt2Y-unsplash.jpg" />
                        </div>
                        <div className="col-md-8">
                            <div className="heading-section mb-5 mt-5 mt-lg-0">
                                <h1 className="main-title">Frequently Asks Questions</h1>

                                <p>Lorem ipsum Sed eu lacus nunc. Suspendisse varius non ligula id aliquam.</p>
                            </div>
                            <div id="accordion" className="myaccordion w-100" aria-multiselectable="true">
                                <div className="card">
                                    <div className="card-header p-0" id="headingOne">
                                        <h2 className="mb-0">
                                            <button href="#collapseOne" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                                                data-parent="#accordion" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
                                                <p className="mb-0">How to Lorem ipsum dolor sit amet?</p>
                                                <i className="fa" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="collapse show" id="collapseOne" role="tabpanel" aria-labelledby="headingOne" >
                                        <div className="card-body py-3 px-0">
                                            <ol>
                                                <li>Lorem ipsum Sed eu lacus nunc. Suspendisse varius non ligula id aliquam. </li>
                                                <li>Lorem ipsum dolor sit amet, consecd aliquam. </li>
                                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed. </li>
                                                <li>Lorem adipiscing elit. Sed eu lacus nunc. Suspendisse varius non ligula id aliquam. </li>
                                                <li>Lorem ipsum id aliquam. </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header p-0" id="headingTwo" role="tab">
                                        <h2 className="mb-0">
                                            <button href="#collapseTwo" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link collapsed"
                                                data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo">
                                                <p className="mb-0">How to manage?</p>
                                                <i className="fa" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="collapse" id="collapseTwo" role="tabpanel" aria-labelledby="headingTwo" >
                                        <div className="card-body py-3 px-0">
                                            <ol>
                                                <li>Lorem ipsum Sed eu lacus nunc. Suspendisse varius non ligula id aliquam. </li>
                                                <li>Lorem ipsum dolor sit amet, consecd aliquam. </li>
                                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed. </li>
                                                <li>Lorem adipiscing elit. Sed eu lacus nunc. Suspendisse varius non ligula id aliquam. </li>
                                                <li>Lorem ipsum id aliquam. </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header p-0" id="headingThree" role="tab">
                                        <h2 className="mb-0">
                                            <button href="#collapseThree" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link collapsed"
                                                data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseThree">
                                                <p className="mb-0">What is the best?</p>
                                                <i className="fa" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="collapse" id="collapseThree" role="tabpanel" aria-labelledby="headingTwo" >
                                        <div className="card-body py-3 px-0">
                                            <ol>
                                                <li>Lorem ipsum Sed eu lacus nunc. Suspendisse varius non ligula id aliquam. </li>
                                                <li>Lorem ipsum dolor sit amet, consecd aliquam. </li>
                                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed. </li>
                                                <li>Lorem adipiscing elit. Sed eu lacus nunc. Suspendisse varius non ligula id aliquam. </li>
                                                <li>Lorem ipsum id aliquam. </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header p-0" id="headingFour" role="tab">
                                        <h2 className="mb-0">
                                            <button href="#collapseFour" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link collapsed"
                                                data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFour">
                                                <p className="mb-0">What are those requirements?</p>
                                                <i className="fa" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="collapse" id="collapseFour" role="tabpanel" aria-labelledby="headingTwo">
                                        <div className="card-body py-3 px-0">
                                            <p className="px-4">
                                                Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large
                                                language ocean.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default faq