import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import $ from 'jquery';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
const axios = require('axios');

const Reviews = () => {
    const [order, setOrder] = useState('');
    const [history, setHistory] = useState('');
    const { user } = useSelector((state) => ({ ...state }));

    useEffect(() => {

        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_ordes.php?email=${user.email}`)
                .then(function (response) {
                    // handle success
                    if (response.data && response.data.length > 0) {
                        console.log('usera');
                        console.log(response.data);
                        console.log('usera');


                        setOrder(response.data);

                    }

                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);



    useEffect(() => {
        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/get_reviews.php?email=${user.email}`)
                .then(function (response) {
                    // handle success
                    if (response.data && response.data.length > 0) {
                        setHistory(response.data);
                    }
                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);


    $('.tabs-nav a').on('click', function (event) {
        event.preventDefault();

        $('.tab-active').removeClass('tab-active');
        $(this).parent().addClass('tab-active');
        $('.tabs-stage .tab-se').hide();
        $($(this).attr('href')).show();
    });
    $('.tabs-nav a:first').trigger('click');

    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">

                    <div className="row">
                    <Profilenav />

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">My Reviews</h3>
                                </div>
                                <ul className="tabs-nav">
                                    <li className="tab-active"><a href="#tab-1" rel="nofollow">To Be Reviewed </a></li>
                                    <li className=""><a href="#tab-2" rel="nofollow">History </a></li>
                                </ul>
                                <div className="tabs-stage">
                                    <div className="tab-se" id="tab-1" style={{ display: "block" }}>

                                        {(order && order !== '') ? (order.map((object, i) =>
                                        <>

                                                {object.cart.map((cartData, j) =>
                                                    <div className="card my-3">
                                                        <div className="card-body">
                                                            <div className="row mb-4">
                                                                <div className="col-12">
                                                                    <p className="text-gray">Purchased on {object.date}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12 col-sm-2">
                                                                    <img src={`${cartData.images[0].url}`} className="img-fluid small-img" />

                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <span className="mt-2 mt-sm-0 product-card-title">{cartData.title}</span>
                                                                    <br />

                                                                </div>
                                                                <div className="col-12 col-sm-4 border-sm-left">
                                                                    <span>Sold by <a className="text-info" href="">gemgolf.online</a></span>
                                                                    <br /><br />
                                                                    <a className="btn btn-outline-danger px-4" href={`/addreviews?oid=${cartData._id}&id=${object._id.$oid}`}>REVIEW</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )


                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <i className="far fa-sad-cry fa-2x text-gray"></i>
                                                        <p className="py-4">You don’t have any purchases to review</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="tab-se" id="tab-2" style={{ display: "none" }}>
                                        {history != '' ? (history.map((object, i) =>
                                            <>
                                                <div className="card my-3">
                                                    <div className="card-body">
                                                        <div className="row mb-4">
                                                            <div className="col-12">
                                                                {/* <p className="text-gray mb-0">Review on </p> */}
                                                                <b>Your product rating & review:</b>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-12 col-sm-2">
                                                                <img src={`http://admin.gemgolf.online/${object.image}`} className="img-fluid small-img" />
                                                            </div>
                                                            <div className="col-12 col-sm-5 col-xl-6">
                                                                <span className="mt-2 mt-sm-0 product-card-title">{object.title}</span>

                                                                <br />
                                                                <div className="rating d-inline-block my-3 mr-3">
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                </div>
                                                                <span className="d-inline-block">Delightful</span>
                                                                <br />
                                                                <div>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-12">
                                                                            <input type="text" className="form-control" id="" value={object.feedback} placeholder={object.feedback} disabled />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="text-info">Pending approval</span>
                                                                {/*<div className="vendor-reply-content">
                                                                    <h3>Reported by vendor</h3>
                                                                    <p>Wrong picture</p>
                                                                </div>*/}
                                                            </div>
                                                            <div className="col-12 col-sm-5 col-xl-4 border-sm-left">
                                                                <span>Sold by <a className="text-info" href="">gemgolf.online</a></span>
                                                                <br /><br />

                                                                <b>Your seller review:</b>
                                                                <br />
                                                                <div className="rating d-inline-block my-3 mr-3">
                                                                    <i className="yellow-star fa-3x fas fa-smile"></i>
                                                                </div>
                                                                <span>Positive</span>
                                                                <div>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-12">
                                                                            <input type="text" className="form-control" id="" value="Positive" placeholder="Positive" disabled />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="text-info">Pending approval</span>
                                                                <br /><br /><br />

                                                                <b>Rate your Rider:</b>
                                                                <br />
                                                                <div className="rating d-inline-block my-3 mr-3">
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                    <i className="star-md yellow-star fas fa-star"></i>
                                                                    <i className="star-md gray-star fas fa-star"></i>
                                                                    <i className="star-md gray-star fas fa-star"></i>
                                                                </div>
                                                                <span>Neutral</span>
                                                                <div>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-12">
                                                                            <input type="text" className="form-control" id="" value={object.overall} placeholder={object.overall} disabled />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="text-info">Pending approval</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        )

                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <i className="far fa-sad-tear fa-2x text-gray"></i>
                                                        <p className="py-4">You haven't written any review</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Reviews