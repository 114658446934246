import React, { useState, useEffect } from "react";
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom'
const axios = require('axios');

const Requestcancellation = () => {
    const [order, setOrder] = useState('');
    const { user } = useSelector((state) => ({ ...state }));
    const [resion, setResion] = useState([]);
    const [pid, setPid] = useState([]);
    const [txtoption, setTxtoption] = useState('');
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [qty, setQty] = useState('');

    const urlParams = new URLSearchParams(window.location.search);

    let idorder = urlParams.get('oid');
    let productId = urlParams.get('pid');

    useEffect(() => {
        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_order.php?email=${user.email} & oid=${idorder}`)
                .then(function (response) {
                    // handle success
                    if (response.data && response.data.length > 0) {
                        setOrder(response.data);
                    }

                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!document.getElementById('acceptval').checked) {
            alert('please accept terms and conditions');
        }
        else {
            axios.post("https://admin.onme.lk/ajax/add_returns.php", {
                email: user.email,
                product: productId,
                orderid: idorder,
                information: txtoption,
                reson: resion,
                image: image,
                title: title,
                qty: qty
            })
                .then((response) => {
                    toast.success("success", {
                        position: toast.POSITION.TOP_LEFT
                    });
                    window.location.href = `/Cancellationresult?oid=${idorder} &pid=${productId}`;
                    ///
                    // history.push(`/Cancellationresult?oid=${idorder} &pid=${productId}`);
                    // return <Redirect to={`/Cancellationresult?oid=${idorder} &pid=${productId}`}  />
                })
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
    }


    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">

                        <Profilenav />
                        {/* <button id="sidebarCollapse" type="button" className="btn btn-light bg-white rounded-pill shadow-sm mb-4"><i className="fa fa-bars"></i></button> */}

                        <div className="col-12 col-md-8">
                            <div class="card-header bg-white no-border text-center">
                                <h3 class="my-3">Request Cancellation</h3>
                            </div>

                            <form className="customized-form-2">
                                <div className="card card-2 p-3 mb-3">
                                    <div className="card-header bg-white no-border text-center">
                                        <h4 className="my-3 text-left">Choose the item(s) you want to cancel</h4>
                                    </div>
                                    <div className="card-body">

                                        {order != '' ? (order.map((object, i) =>
                                            <>
                                                {object.cart.map((cartData, j) =>
                                                    <div className="cancellation-items">
                                                        {productId == cartData._id ? (
                                                            <div className="row mb-4">
                                                                <div className="col-12 col-sm-9 col-lg-1 col-xl-1">
                                                                    <label className="container-checkbox">&nbsp; &nbsp; &nbsp;
                                                                        <input type="checkbox" onclick={(e) => { setPid(cartData._id) }} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 col-sm-9 col-lg-5 col-xl-5">
                                                                    <div className="set-inline img-c">
                                                                        <img src={`http://admin.gemgolf.online/${cartData.images[0].url}`} className="img-fluid" />
                                                                        <p>{cartData.title}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-9 col-lg-2 col-xl-2">
                                                                    <span className="text-gray">Qty: </span><span className="text-dark">{cartData.count}</span>
                                                                </div>
                                                                <div className="col-12 col-sm-9 col-lg-4 col-xl-4">
                                                                    <div class="select-dropdown">
                                                                        <select onChange={(e) => {
                                                                            setResion(e.target.value); setImage(cartData.images[0].url); setTitle(cartData.title);

                                                                            setQty(cartData.count)

                                                                        }}>
                                                                            <option></option>
                                                                            <option value="change / combine order">change / combine order</option>
                                                                            <option value=" Delivery time is too long"> Delivery time is too long</option>
                                                                            <option value="Duplicate Order"> Duplicate Order</option>
                                                                            <option value="Change Delivery Address">Change Delivery Address</option>
                                                                            <option value="Shipping fees too high">Shipping fees too high</option>
                                                                            <option value="change of mind">change of mind</option>
                                                                            <option value="Forgot to use coupon code">Forgot to use coupon code</option>
                                                                            <option value="Decided to buy an alternative product">Decided to buy an alternative product</option>
                                                                            <option value="Found a cheaper product">Found a cheaper product</option>
                                                                            <option value="Change Payment Method"> Change Payment Method  </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : ' '}
                                                    </div>

                                                )}
                                            </>
                                        )

                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <p className="py-4">There are no orders placed yet.</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING </a>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="card card-2 p-3 mb-3">
                                    <div className="card-header bg-white no-border text-center">
                                        <h4 className="text-left">Additional Information (optional)</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="cancellation-items">
                                            <div className="row mb-4">
                                                <div className="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Eg My phone has missing headphones" onChange={(e) => { setTxtoption(e.target.value) }}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-2 p-3 mb-5">
                                    <div className="card-header bg-white no-border text-center">
                                        <h4 className="text-left">Cancellation Policy</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="content-policy">
                                            <div className="row mb-4">
                                                <div className="col-12 col-sm-12">
                                                    <div className="content-policy-body">
                                                        <p>Before cancelling the order, kindly read thoroughly our following terms & conditions:</p>
                                                        <ol className="pl-15">
                                                            <li>Once you submit this form you agree to cancel the selected item(s) in your order. We will be unable to retrieve your order once it is cancelled.</li>
                                                            <li>Once you submit this form you agree to cancel the selected item(s) in your order. We will be unable to retrieve your order once it is cancelled.</li>
                                                            <li>Once you submit this form you agree to cancel the selected item(s) in your order. We will be unable to retrieve your order once it is cancelled.</li>
                                                            <li>Once your item(s) has been successfully cancelled you will receive a notification from us with your refund summary.</li>
                                                        </ol>
                                                    </div>
                                                    <label className="container-checkbox">
                                                        <input type="checkbox" id="acceptval" /> I have read and accepted the Cancellation Policy of Golf Gem.
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-4 mb-4">
                                            <div className="col-12 col-sm-12 text-center">
                                                <button className="btn btn-danger mx-1" onClick={handleSubmit}>Submit</button>
                                                <button className="btn btn-info mx-1" onClick={handleCancel}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Requestcancellation