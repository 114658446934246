import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';

const Editaddress = () => {
    return (
        <>
            <div className="page-content mt-3" id="content">
                <div className="row">
                    <div className="col-12 col-xl-8 offset-xl-1">
                        <div className="card card-2 p-3 mb-5">
                            <div className="card-header bg-white no-border text-center">
                                <h3 className="my-3">Edit Address</h3>
                            </div>
                            <div className="card-body">
                                <form className="customized-form">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-row">
                                                <div className="form-group col-12">
                                                    <label for="">Full name</label>
                                                    <input type="text" className="form-control" id="" placeholder="Enter your first and last name" />
                                                </div>
                                                <div className="form-group col-12">
                                                    <label for="">Phone Number</label>
                                                    <input type="tel" className="form-control" id="" placeholder="Please enter your phone number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-row">
                                                <div className="form-group col-12">
                                                    <label for="">Province</label>
                                                    <select className="custom-select">
                                                        <option selected disabled>Please choose your province</option>
                                                        <option value="1">Central</option>
                                                        <option value="2">Eastern</option>
                                                        <option value="3">North Central</option>
                                                        <option value="4">Northern</option>
                                                        <option value="5">North Western</option>
                                                        <option value="6">Sabaragamuwa</option>
                                                        <option value="7">Southern</option>
                                                        <option value="8">Uva</option>
                                                        <option value="9">Western</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-12">
                                                    <label for="">District</label>
                                                    <select className="custom-select disabled">
                                                        <option selected disabled>Please choose your district</option>
                                                        <option value="1">Kalutara</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-12">
                                                    <label for="">City</label>
                                                    <select className="custom-select disabled">
                                                        <option selected disabled>Please choose your city</option>
                                                        <option value="1">Panadura</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-12">
                                                    <label for="">Address</label>
                                                    <input type="text" className="form-control" id="" placeholder="For Example: House# 123, Street# 123, ABC Road" />
                                                </div>
                                                <div className="form-group col-12">
                                                    <label for="">Select a label for effective delivery:</label><br />
                                                    <div class="wrapper">
                                                        <input type="radio" name="select" id="option-1" checked />
                                                        <input type="radio" name="select" id="option-2" />
                                                        <label for="option-1" class="option option-1">
                                                            <span><i class="fas fa-home pr-2"></i> Home</span>
                                                        </label>
                                                        <label for="option-2" class="option option-2">
                                                            <span><i class="fas fa-building pr-2"></i> Office</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <a href="profile_address_book.php">
                                                        <button type="button" className="button-size-md btn btn-dark">CANCEL</button>
                                                    </a>
                                                </div>
                                                <div className="form-group col-6">
                                                    <button type="button" className="product-button button-size-md button-lightblue">UPDATE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterItem />
        </>
    )
}

export default Editaddress