import React, { useState, useEffect } from "react";
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'antd/dist/antd.css';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const props = {
   name: 'file',
   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
   headers: {
      authorization: 'authorization-text',
   },
   onChange(info) {
      if (info.file.status !== 'uploading') {
         console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
         message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
         message.error(`${info.file.name} file upload failed.`);
      }
   },
};

const requestReturn = () => {
   return (

      <>
         <section className="page-content mt-3 container">
            <div class="" id="content">
               <div class="row">

                  <Profilenav />
                  {/* <button id="sidebarCollapse" type="button" class="btn btn-light bg-white rounded-pill shadow-sm mb-4"><i class="fa fa-bars"></i></button> */}

                  <div class="col-12 col-md-8">
                     <div class="card-header bg-white no-border text-center">
                        <h3 class="my-3">Request Return</h3>
                     </div>

                     <form class="customized-form-2">
                        <div class="card card-2 p-3 mb-3">
                           <div class="card-header bg-white no-border text-center">
                              <h4 class="my-3 text-left">Choose the item(s) you want to return</h4>
                           </div>
                           <div class="card-body">
                              <div class="cancellation-items">
                                 <div class="row mb-4">
                                    <div class="col-12 col-sm-9 col-lg-1 col-xl-1">
                                       <label class="container-checkbox">&nbsp; &nbsp; &nbsp;<input type="checkbox" /><span class="checkmark"></span></label>
                                    </div>
                                    <div class="col-12 col-sm-9 col-lg-5 col-xl-5">
                                       <div class="set-inline img-c"><img src="http://admin.gemgolf.online/" class="img-fluid" /><p>LB-LINK 150Mbps Nano Wireless USB Adapter</p></div>
                                    </div>
                                    <div class="col-12 col-sm-9 col-lg-2 col-xl-2"><span class="text-gray">Qty: </span><span class="text-dark">1</span></div>
                                    <div class="col-12 col-sm-9 col-lg-4 col-xl-4">
                                       <div class="select-dropdown">
                                          <select>
                                             <option></option>
                                             <option value="change / combine order">change / combine order</option>
                                             <option value=" Delivery time is too long"> Delivery time is too long</option>
                                             <option value="Duplicate Order"> Duplicate Order</option>
                                             <option value="Change Delivery Address">Change Delivery Address</option>
                                             <option value="Shipping fees too high">Shipping fees too high</option>
                                             <option value="change of mind">change of mind</option>
                                             <option value="Forgot to use coupon code">Forgot to use coupon code</option>
                                             <option value="Decided to buy an alternative product">Decided to buy an alternative product</option>
                                             <option value="Found a cheaper product">Found a cheaper product</option>
                                             <option value="Change Payment Method"> Change Payment Method  </option>
                                          </select>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card card-2 p-3 mb-3">
                           <div class="card-header bg-white no-border text-left">
                              <h4 class="text-left">Attachments</h4>
                              <p>Please attach a clear image of the product you want to return</p>
                           </div>
                           <div class="card-body">
                              <div class="cancellation-items">
                                 <div class="row mb-4">
                                    <div class="col-12 col-sm-12">
                                       <Upload {...props}>
                                          <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                       </Upload>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card card-2 p-3 mb-3">
                           <div class="card-header bg-white no-border text-center">
                              <h4 class="text-left">Additional Information (optional)</h4>
                           </div>
                           <div class="card-body">
                              <div class="cancellation-items">
                                 <div class="row mb-4">
                                    <div class="col-12 col-sm-12">
                                       <div class="form-group"><textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Eg My phone has missing headphones"></textarea></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card card-2 p-3 mb-5">
                           <div class="card-header bg-white no-border text-center">
                              <h4 class="text-left">Return Policy</h4>
                           </div>
                           <div class="card-body">
                              <div class="content-policy">
                                 <div class="row mb-4">
                                    <div class="col-12 col-sm-12">
                                       <div class="content-policy-body">
                                          <p>Before return the order, kindly read thoroughly our following terms &amp; conditions:</p>
                                          <ol class="pl-15">
                                             <li>Once you submit this form you agree to cancel the selected item(s) in your order. We will be unable to retrieve your order once it is cancelled.</li>
                                             <li>Once you submit this form you agree to cancel the selected item(s) in your order. We will be unable to retrieve your order once it is cancelled.</li>
                                             <li>Once you submit this form you agree to cancel the selected item(s) in your order. We will be unable to retrieve your order once it is cancelled.</li>
                                             <li>Once your item(s) has been successfully cancelled you will receive a notification from us with your refund summary.</li>
                                          </ol>
                                       </div>
                                       <label class="container-checkbox"><input type="checkbox" id="acceptval" /> I have read and accepted the Return Policy of Golf Gem.<span class="checkmark"></span></label>
                                    </div>
                                 </div>
                              </div>
                              <div class="row pt-4 mb-4">
                                 <div class="col-12 col-sm-12 text-center"><button class="btn btn-danger mx-1">Submit</button><button class="btn btn-info mx-1">Cancel</button></div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </section>

         <FooterItem />
      </>
   )
}

export default requestReturn