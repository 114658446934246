import React from 'react'

const FooterItem = () => {
    return (
        <footer>
            <div class="desktop-footer">
                <div className="footer_1">
                    <div className="container">
                        <div className="row py-5">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="my-4">
                                    <a href="" className="text-black">
                                        <img src="/images/gemlogo-black.png" className="footer-logo img-fluid" />
                                    </a>
                                </div>
                                <ul>
                                    <li><a href="" target="_blank"><i className="fa fa-map mr-3 text-dark"></i>AI Moosa Tower 2, Office 2303.</a></li>
                                    <li><a href="mailto:support@golfgem.com"><i className="fa fa-envelope mr-3 text-dark"></i>support@golfgem.com </a></li>
                                    <li><a href="tel:001 234 5678"><i className="fa fa-phone mr-3 text-dark"></i>001 234 5678</a></li>
                                </ul>
                                <div className="social-media">
                                    <ul className="list-unstyled list-inline social">
                                        <li className="list-inline-item pr-1"><a href="" target="_blank"><img class="insta-logo" src="/images/insta.svg" /></a></li>
                                        <li className="list-inline-item"><a href="" target="_blank"><img class="linkedin-logo" src="/images/linkedin.svg" /></a></li>
                                        <li className="list-inline-item"><a href="" target="_blank"><img class="fb-logo" src="/images/fb.svg" /></a></li>
                                        <li className="list-inline-item"><a href="" target="_blank"><img class="pinterest-logo" src="/images/pinterest.svg" /></a></li>
                                        <li className="list-inline-item"><a href="" target="_blank"><img class="tiktok-logo" src="/images/tiktok.svg" /></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                                <h3 className="footer-title mb-4">QUICK LINKS</h3>
                                <ul>
                                    <li><a href="/about">About </a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                    <li><a href="/privacy">Privacy Policy</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                                <h3 className="footer-title mb-4"> CUSTOMER CARE </h3>
                                <ul>
                                    <li><a href="/faq">FAQ </a></li>
                                    <li><a href="/terms">Terms & Conditions</a></li>
                                    <li><a href="">Track Order</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                                <h3 className="footer-title mb-4"> INFORMATION </h3>
                                <ul>
                                    <li><a href="">Shipping & Returns</a></li>
                                    <li><a href="">Careers</a></li> 
                                    <li><a href="">Become a Vendor</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 col-lg-3">
                                <h4 className="footer-title-2 mb-4">
                                    <a href="">
                                        GET 5% OFF
                                        <br />
                                        YOUR FIRST PURCHASE
                                    </a>
                                </h4>
                                <p>& be the first to know about new arrivals, special offers, in-store events and news.</p>
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-12 d-flex">
                                            <input type="email" class="form-control" name="email" placeholder="Your email address" />
                                            <button class="join-button button-size-md button-lightblue">JOIN</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider-outer">
                    <span className="divider-separator"></span>
                </div>
                <div className="footer_2">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-last">
                                <div className="card-item-body card-logo">
                                    <div className="card-item">
                                        <img src="/images/visa-pay-logo.svg" alt="golfgem" />
                                    </div>
                                    <div className="card-item">
                                        <img src="/images/master-card-logo.svg" alt="golfgem" />
                                    </div>
                                    <div className="card-item">
                                        <img src="/images/american-express-logo.svg" alt="golfgem" />
                                    </div>
                                    <div className="card-item">
                                        <img src="/images/paypal-logo.svg" alt="golfgem" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <p class="copyright-text"> Copyright © 2022. All Rights Reserved. Designed by <a href="https://www.redberylit.com/">Redberyl IT</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-footer d-block d-lg-none">
                <ul class="nav nav-pills nav-fill">
                    <li class="nav-item">
                        <a class="nav-link active" href="/">
                            <i class="las la-home mobile-footer-icons"></i><br></br> Home
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/shop?cat=">
                        <i class="las la-th-large mobile-footer-icons"></i> <br></br> Categories
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cart">   
                        <i class="las la-shopping-bag mobile-footer-icons"></i> <br></br> Cart
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="user/wishlist">
                            <i class="lar la-heart mobile-footer-icons"></i> <br></br> Wishlist
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/profile">
                        <i class="lar la-user-circle mobile-footer-icons"></i> <br></br> My Golf Gem
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default FooterItem