import * as firebase from "firebase";

/*
const firebaseConfig = {
    apiKey: "AIzaSyADRhO7loBM-OZwzsdH_I1LRhk4YvWkeug",
    authDomain: "ecommerce-65e95.firebaseapp.com",
    projectId: "ecommerce-65e95",
    storageBucket: "ecommerce-65e95.appspot.com",
    messagingSenderId: "1047245659972",
    appId: "1:1047245659972:web:1c5870369470cf33858111"
};
*/

const firebaseConfig = {
  apiKey: "AIzaSyB7gnw4AWgDl3GSIzHbHTYzQRHGcZeQN68",
  authDomain: "onme-c40b4.firebaseapp.com",
  projectId: "onme-c40b4",
  storageBucket: "onme-c40b4.appspot.com",
  messagingSenderId: "792769198987",
  appId: "1:792769198987:web:d9e51189b2bbe8e3cc8d8e",
  measurementId: "G-R8C7P3EV76"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// export
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();