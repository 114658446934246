import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import '../login_register_forgotpw.css';
import '../profile.css';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
const Profile = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => ({ ...state }));

    useEffect(() => {
        setLoading(true);
        getCollection().then((c) => {
            setCategories(c.data);
            setLoading(false);
        });
    }, []);

    return (
        <>

            <section className="page-content mt-3 container">
                <div className="row">
                    <Profilenav />

                    <div className="col-12 col-md-8">
                        <div className="card card-2 p-3 mb-5">
                            <div className="card-header bg-white no-border text-center">
                                <h3 className="my-3"><span className="profile-detail-div">My Profile</span><span className="change-pw-div d-none">Change Password</span></h3>
                            </div>
                            <div className="card-body">
                                <form className="customized-form">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <span className="profile-detail-div">
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Full Name</label>
                                                        <input type="text" className="form-control edit-prof" id="" value={user && user.name ? user.name : ''} placeholder="Enter your first and last name" disabled />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Email Address | <a className="text-success" href="/security_verification">Change</a> </label>
                                                        <input type="email" className="form-control" id="" placeholder="Please enter your email" value={user && user.email ? user.email : ''} disabled />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Mobile</label>
                                                        <input type="tel" className="form-control edit-prof" id="" placeholder="Please enter your mobile" disabled />
                                                    </div>
                                                </div>
                                            </span>
                                            <span className="change-pw-div d-none">
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Current password</label>
                                                        <input type="password" className="form-control" id="" placeholder="Please enter your current password" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">New password</label>
                                                        <input type="password" className="form-control" id="" placeholder="Minimum 6 characters with a number and a letter" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Retype password</label>
                                                        <input type="password" className="form-control" id="" placeholder="Please retype your password" />
                                                    </div>
                                                </div>
                                                <div className="form-row save-pw d-none">
                                                    <div className="form-group col-12">
                                                        <button type="button" className="gradient-button save-pw-btn" data-toggle="modal" data-target=".save-password-modal">SAVE PASSWORD</button>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <span className="profile-detail-div">
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Birthday</label>
                                                        <input type="text" className="form-control edit-prof" name="date" placeholder="Day / Month / Year" disabled />
                                                        <i className="far fa-calendar-alt form-icon"></i>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Gender</label>
                                                        <select className="custom-select edit-prof" disabled>
                                                            <option selected disabled>Select</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12 col-sm-10 col-md-12 col-lg-10">
                                                        <a className="reg-here" href="" data-toggle="modal" data-target=".newsletter-modal">Subscribe to our Newsletter</a>
                                                    </div>
                                                </div>
                                                <div className="form-row edit-pro">
                                                    <div className="form-group col-12">
                                                        <button type="button" className="gradient-button edit-pro-btn">EDIT PROFILE</button>
                                                    </div>
                                                </div>
                                            </span>
                                            <div className="form-row change-pw">
                                                <div className="form-group col-12">
                                                    <Link to="/user/password">
                                                        <button className="gradient-button change-pw-btn">CHANGE PASSWORD</button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="form-row save-changes d-none">
                                                <div className="form-group col-12">
                                                    <button type="button" className="gradient-button save-changes-btn">SAVE CHANGES</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Profile