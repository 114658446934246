import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Checkoutprocess.css';
import $ from 'jquery';
import { useSelector, useDispatch } from "react-redux";
import { userCart } from "../functions/user";
import { Separator } from "./../functions/separator";

const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const { cityList, provinceList, getJsonofCityAndDistrict, getDistrictList } = require('get-srilanka-districts-cities');

const Checkoutprocess = ({ history }) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [province, setProvince] = useState('Western Province');
    const [phone, setPhone] = useState('');
    const [phonealt, setPhonealt] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [town, setTown] = useState([]);
    const [cities, setCities] = useState([]);
    const [address, setAddress] = useState('');
    const [cartData, setCartData] = useState([]);
    const [email, setEmail] = useState('');

    const { user } = useSelector((state) => ({ ...state }));


    useEffect(() => {
        const item = localStorage.getItem('cart')
        if (item) {
            setCartData(item)
            //console.log(item);
        }
    }, []);

    var Cartdata = [];
    Cartdata = JSON.parse(localStorage.getItem("cart"));

    let shippingFee = localStorage.getItem("shipping_free") == null ? 0 : localStorage.getItem("shipping_free");

    console.log(shippingFee);

    const [shipping, setShipping] = useState(shippingFee);

    let count = 0;
    if (Cartdata) {
        Cartdata.map((object, i) => {
            // total=total+(object.count *object.price )
            count = count + object.count;
        }
        );
    }
    else {
        count = 0;
    }

    const options = [
        { value: 'Central', label: 'Central' },
        { value: 'North Central', label: 'North Central' },
        { value: 'North Eastern', label: 'North Eastern' },
        { value: 'North Western', label: 'North Western' },
        { value: 'Sabaragamuwa', label: 'Sabaragamuwa' },
        { value: 'Southern', label: 'Southern' },
        { value: 'Uva', label: 'Uva' },
        { value: 'Western', label: 'Western' }
    ]

    const [addressbook, setAdderessbook] = useState('');

    useEffect(() => {

        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_adderess.php?email=${user.email}&status=cancelled`)
                .then(function (response) {
                    // handle success
                    setAdderessbook(response.data);
                    setName(response.data[0].name)
                    setPhone(response.data[0].phone)
                    setDistrict(response.data[0].district)
                    setCity(response.data[0].city)
                    setAddress(response.data[0].address)

                    //address
                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);

    useEffect(() => {
        setLoading(true);
        getCollection().then((c) => {
            setCategories(c.data);
            setLoading(false);
        });
    }, []);

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    let validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(`n${name.length}p ${phone.length} pr ${province} ci ${city} dis ${district} em ${email}`)
        let email1 = (email == '' && user) ? user.email : email;
        if (name.length === 0) {
            toast.error("Please enter name", {
                position: toast.POSITION.TOP_LEFT
            });

        }
        else if (phone.length != 10) {
            toast.error("Please enter valid phone", {
                position: toast.POSITION.TOP_LEFT
            });

        }
        else if (province.length == 0) {
            toast.error("Please select a province to proceed", {
                position: toast.POSITION.TOP_LEFT
            });

        }
        else if (city.length == 0) {
            toast.error("Please select a city to proceed", {
                position: toast.POSITION.TOP_LEFT
            });

        }
        else if (district.length == 0) {
            toast.error("Please select a district to proceed", {
                position: toast.POSITION.TOP_LEFT
            });

        }
        else if (!validateEmail(email1)) {
            toast.error("Please enter a valid email", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        else {
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            axios.post('http://admin.gemgolf.online/ajax/placeorder.php', {
                name: name,
                phone: phone,
                phonealt: phonealt,
                province: province,
                district: district,
                city: city,
                address: address,
                shipping: localStorage.getItem("shipping"),
                count: count,
                total: total,
                cart: Cartdata,
                status: 'processing',
                email: email1
            })
                .then(function (response) {
                    axios.post('https://be.onme.lk/api/order', {
                        name: name,
                        phone: phone,
                        phonealt: phonealt,
                        province: province,
                        district: district,
                        city: city,
                        address: address,
                        shipping: localStorage.getItem("shipping"),
                        count: count,
                        total: total,
                        cart: Cartdata,
                        status: 'processing',
                        email: email1,
                        oid: response.body,
                    })
                        .then(function (response) {
                        });
                    toast.success("Success", {
                        position: toast.POSITION.TOP_LEFT
                    });
                    localStorage.removeItem('cart');
                    localStorage.setItem("name", name);
                    history.push("/ordercomplete");
                })
                .catch(function (error) {
                    toast.error("Error", {
                        position: toast.POSITION.TOP_LEFT
                    });
                })
        }
    }


    //     $(document).ready(function(){
    //         $(document).on('change', '#province', function() {    
    // var pval=$(this).val();
    // $.get("http://admin.gemgolf.online/ajax/deliveryjson?district="+ pval, function(data, status){
    //              var list='';
    //         $.each(data, function(i, item) {
    //             list +='<option value="">'+item+'</option>';
    //         });
    //         $('#province').html(list);
    //       });
    // });
    // });
    //(e) => setProvince(e.target.value)


    let handleCityChange = async (e) => {
        setProvince(e.target.value)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                let jsoncity = await axios.get('https://admin.onme.lk/ajax/deliveryjson?district=' + province);
                setCities(jsoncity.data);
                console.log(jsoncity);

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

        console.log('cities');
        console.log(cities);
        console.log('cities');
    }, [province]);


    useEffect(() => {
        const fetchData2 = async () => {
            setLoading(true);
            try {
                let jsoncity2 = await axios.get('https://admin.onme.lk/ajax/deliveryjson?city=' + district);
                setTown(jsoncity2.data);
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData2();

    }, [district]);


    useEffect(() => {
        const fetchData3 = async () => {
            if (!city) {
                setShipping(0);
            }
            else {
                try {
                    let jsoncity3 = await axios.get('https://admin.onme.lk/ajax/deliveryjson?final=' + city);

                    if (jsoncity3.data == '') {
                        setShipping(0);
                        localStorage.setItem("shipping", 0);
                        localStorage.setItem("shipping_free", 0);
                    }
                    else {
                        setShipping(jsoncity3.data);
                        localStorage.setItem("shipping", jsoncity3.data);
                        localStorage.setItem("shipping_free", jsoncity3.data);
                    }
                } catch (error) {
                    console.error(error.message);
                }
                setLoading(false);
            }
        }
        fetchData3();
    }, [city]);

    var Cartdata = [];
    Cartdata = JSON.parse(localStorage.getItem("cart"));

    let total = 0;
    let total2 = 0;

    //let count=0;
    if (Cartdata) {
        Cartdata.map((object, i) => {

            let pp = object.sprice != '' ? object.sprice : object.price;
            total = total + (object.count * pp)
            //total=total+(object.count *pp )
            //   console.log('total '+(object.price ) +' '+object.title );
            total2 = total2 + (object.count * pp)
            // count=count+object.count;
        }

        );
    }


    return (
        <>

            <section className="collection">
                <div className="container my-3">
                    <div className="row">
                        <div className="col-12 col-md-8">

                            <div className="card no-border">
                                <div className="card-header bg-white no-border">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="section-main-title">Delivery Information</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="customized-form">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Full name</label>
                                                        <input type="text" className="form-control"
                                                            value={name} name={(name === '' && user) ? user.name : name}
                                                            onChange={(e) => setName(e.target.value)} id="" placeholder="Enter your first and last name" />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label for="">Phone Number</label>
                                                        <input type="tel" className="form-control" id="" value={phone} name={phone}
                                                            onChange={(e) => setPhone(e.target.value)} placeholder="Please enter your phone number" />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label for="">Alternate phone Number</label>
                                                        <input type="tel2" className="form-control" id="" value={phonealt} name={phonealt}
                                                            onChange={(e) => setPhonealt(e.target.value)} placeholder="Please enter your alternate phone number" />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label for="">Email</label>
                                                        <input type="text" className="form-control"
                                                            name={email} placeholder="Enter your email address" value={(email == '' && user) ? user.email : email} 
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label for="">Province</label>
                                                        <select className="custom-select" onChange={handleCityChange} id="province">
                                                            <option selected>Please choose your province</option>
                                                            <option value="Central">Central</option>
                                                            <option value="Eastern">Eastern</option>
                                                            <option value="North Central3">North Central</option>
                                                            <option value="Northern">Northern</option>
                                                            <option value="North Western">North Western</option>
                                                            <option value="Sabaragamuwa">Sabaragamuwa</option>
                                                            <option value="Southern">Southern</option>
                                                            <option value="Uva">Uva</option>
                                                            <option value="Western Province">Western</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label for="">District</label>
                                                        <select className="custom-select" id="district" onChange={(e) => setDistrict(e.target.value)} >
                                                            <option value="">Select</option>

                                                            {cities.length > 1 && cities.map((value, i) =>
                                                                <option key={value}>{value}</option>
                                                            )}
                                                        </select>

                                                        {/* <select>
                                                    {items.map(({ label, value }) => (
                                                        <option key={value} value={value}>
                                                        {label}
                                                        </option>
                                                    ))}
                                                    </select> */}

                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label for="">City</label>
                                                        <select className="custom-select " onChange={(e) => setCity(e.target.value)} id="city">
                                                            <option value="">Select</option>
                                                            {town.map((value, i) =>
                                                                <option key={value}>{value}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label for="">Address</label>
                                                        <input type="text" className="form-control" id="" onChange={(e) => setAddress(e.target.value)}
                                                            value={address} placeholder="For Example: House# 123, Street# 123, ABC Road" />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label for="">Select a label for effective delivery:</label><br />
                                                        <div class="wrapper">
                                                            <input type="radio" name="select" id="option-1" checked />
                                                            <input type="radio" name="select" id="option-2" />
                                                            <label for="option-1" class="option option-1">
                                                                <span><i class="fas fa-home pr-2"></i> Home</span>
                                                            </label>
                                                            <label for="option-2" class="option option-2">
                                                                <span><i class="fas fa-building pr-2"></i> Office</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    {/* <div className="form-group col-12 col-xl-6 offset-xl-6">
                                                        <button type="submit" className="product-button button-size-md button-lightblue" onClick={handleSubmit}>SAVE</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="card no-border my-3">
                                <div className="card-header bg-white no-border">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="section-main-title pt-3">Payment Method</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="p-2 p-method d-inline-block mr-2 mb-2">
                                                <p className="card-title"><i className="fas fa-check-circle text-success pr-1"></i> Cash on Delivery</p>
                                            </div>
                                            <div class="p-2 p-method d-inline-block mr-2 mb-2">
                                                <p class="card-title"><i class="fas fa-check-circle text-success pr-1"></i> Credit/Debit card payments</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card no-border my-3 package-card d-none d-lg-block">
                                <div className="card-header no-border">
                                    <div className="row">
                                        <div className="col-7">
                                            <span className="section-sub-title"><span>{count}</span> ITEMS</span>
                                        </div>
                                        <div className="col-3">
                                            <span className="section-sub-title">PRICE</span>
                                        </div>
                                        <div className="col-2">
                                            <span className="section-sub-title">QUANTITY</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card no-border my-3 package-card">
                                <div className="card-header no-border">
                                    <div className="row">
                                        <div className="col-6">
                                            <h2 className="section-main-title">&nbsp;</h2>
                                        </div>
                                        <div className="col-6">
                                            <small className="float-right package-title">Shipped by <b>Golf Gem</b></small>
                                        </div>
                                    </div>
                                </div>

                                {Cartdata !== null ? (Cartdata.map((object, i) =>
                                    <div className="card-body">
                                        {/* <div className="row">
                                                <div className="col-12 col-sm-6 col-xl-4">
                                                    <small>Delivery Option</small>
                                                    <div className="card border-info mt-2 mb-3">
                                                        <div className="p-2 text-dark">
                                                            <b className="card-title"> <i className="fas fa-check-circle text-success pr-1"></i> Rs. {isNaN(shipping)?0:shipping}</b>
                                                            <br/><small>Home Delivery</small>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        <div className="row">
                                            <div className="col-12 col-sm-3 col-lg-3 col-xl-2">
                                                <img src={object.images && (object.images.length > 1) ? (validURL(object.images[1].url) ? object.images[1].url : 'http://admin.gemgolf.online/' + object.images[1].url) : (validURL(object.images[0].url) ? object.images[0].url : './default.png')} className="img-fluid" />
                                            </div>
                                            <div className="col-12 col-sm-9 col-lg-4 col-xl-5">
                                                <div className="content">
                                                    <p className="title mt-2 mt-sm-0">{object.title}</p>
                                                    <p className="small-gray-text"></p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-3 col-xl-3">
                                                <span className="price">AED. {object.sprice !== '' ? Separator(object.sprice) : Separator(object.price)}</span><br />
                                                <span className="origin-price">AED. {object.sprice !== '' ? Separator(object.price) : ''}</span>
                                                <span className="promotion-ratio"></span><br />
                                                <button type="button" className="btn-tansparent trash-icon p-0" data-toggle="modal" data-target="#delete-checkout">
                                                    <i className="far fa-trash-alt"></i>
                                                </button>
                                            </div>
                                            <div className="col-6 col-sm-4 col-lg-2 col-xl-2">
                                                <p className="qty float-right">Qty: <span>{object.count}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                )) : ''}
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="card no-border">
                                <div className="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <h2 class="section-main-title text-center mb-2">Confirm Order</h2>
                                        </div>
                                    </div>
                                    <form className="customized-form">
                                        <div className="form-row">
                                            <div className="form-group col-12"> 
                                                {/* <button type="submit" className="product-button button-size-md button-lightgreen" onClick={handleSubmit}>Confirm Order</button> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5 className="mb-3">Order Summary</h5>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-6">
                                                <div className="gray-label">Subtotal (1 Items)</div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div className="float-right">AED. {total.toLocaleString('en')}</div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-6">
                                                <div className="gray-label">Shipping Fee</div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div className="float-right">AED. {shipping === 0 && localStorage.getItem("shipping_free") ? localStorage.getItem("shipping_free").toLocaleString('en') : shipping.toLocaleString('en')}</div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-8">
                                                <input type="text" className="form-control" id="" placeholder="Enter Voucher Code" />
                                            </div>
                                            <div className="form-group col-4">
                                                <button type="submit" className="product-button button-size-md button-lightblue">APPLY</button>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-4">
                                                <div className="">Total:</div>
                                            </div>
                                            <div className="form-group text-right col-8">
                                                <span className="price">AED. {(total + parseInt(shipping)).toLocaleString('en')}</span><br />
                                                <small className="">(VAT included, where applicable)</small>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <button type="submit" className="product-button button-size-md button-lightgreen disabled" onClick={handleSubmit}>Confirm Order</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>


    )
}

export default Checkoutprocess