import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import $ from 'jquery';
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './rating.css';
import 'antd/dist/antd.css';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const axios = require('axios');

const { Dragger } = Upload;
const props = {
    name: 'file',
    multiple: true,
    action: '',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const Addreviews = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderID = urlParams.get('id');

    let idorder = urlParams.get('oid');
    const [order, setOrder] = useState('');

    const [feedback, setFeedback] = useState('');
    const [overall, setOverall] = useState('');
    const [rider, setRider] = useState('');
    const [riderfeedback, setRiderfeedback] = useState('');
    const [star, setStar] = useState('');
    const { user } = useSelector((state) => ({ ...state }));

    useEffect(() => {

        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/get_product.php?oid=${idorder}`)
                .then(function (response) {
                    // handle success
                    setOrder(response.data);

                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);

    const handleSubmit = (e) => {
        if (feedback == '') {
            toast.error("purchased product review can not be empty", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        else if (overall == '') {
            toast.error("purchased product review can not be empty", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        else {
            toast.success("success", {
                position: toast.POSITION.TOP_LEFT
            });
        }
        const { image, title } = e.target.dataset;


        axios.post("https://admin.onme.lk/ajax/add_reviews.php", {
            email: user.email,
            product: idorder,
            star: star,
            feedback: feedback,
            overall: overall,
            rider: rider,
            riderfeedback: riderfeedback,
            image: image,
            title: title,
            _id: orderID
        })
            .then((response) => {
                console.log(response);
            })
    }

    const handlestar = (e) => {
        const { param } = e.target.dataset;
        setStar(param);
        console.log(star);
    }

    //$('.rate-seller input[type=radio]').click(function() {

    $(document).ready(function () {
        $('.rate-seller input[type=radio]').change(function () {
            var ele = document.getElementsByName('emotion');

            for (var i = 0; i < ele.length; i++) {
                if (ele[i].checked)
                    document.getElementById("result1").innerHTML
                        = ele[i].value;
            }
        });
    });


    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">
                        <Profilenav />

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">Write Review</h3>
                                </div>

                                <div className="card-body">
                                    <div className="card my-3">
                                        <div className="card-body">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    {/* <p className="text-gray mb-0">Purchased on 23 Nov 2020</p> */}
                                                    <small>Rate and review purchased product:</small>
                                                </div>
                                            </div>

                                            {order != '' ? (order.map((object, i) =>
                                                <>
                                                    <div className="row mb-4">
                                                        <div className="col-12 col-sm-2">
                                                            <img src={`http://admin.gemgolf.online/${object.images[0].url}`} className="img-fluid small-img" />
                                                        </div>
                                                        <div className="col-12 col-sm-5 col-xl-6">
                                                            <span className="mt-2 mt-sm-0 product-card-title">{object.title}</span>
                                                            {/* <small className="text-gray">Color Family:Red</small> */}
                                                            <br />

                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="rating1 rate-product">
                                                                        <input type="radio" id="star5" name="rating-star" value="Delightful" onChange={handlestar} data-param="5" /><label for="star5" title="Delightful">Delightful</label>
                                                                        <input type="radio" id="star4" name="rating-star" value="Satisfactory" onChange={handlestar} data-param="4" /><label for="star4" title="Satisfactory">Satisfactory</label>
                                                                        <input type="radio" id="star3" name="rating-star" value="Neutral" onChange={handlestar} data-param="3" /><label for="star3" title="Neutral">Neutral</label>
                                                                        <input type="radio" id="star2" name="rating-star" value="Poor" onChange={handlestar} data-param="2" /><label for="star2" title="Poor">Poor</label>
                                                                        <input type="radio" id="star1" name="rating-star" value="Very Poor" onChange={handlestar} data-param="1" /><label for="star1" title="Very Poor">Very Poor</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <small><b id="result" className="text-success"></b></small>
                                                            <br />
                                                            <small className="d-inline-block">Review detail</small>

                                                            <div>
                                                                <div className="form-row my-2">
                                                                    <div className="form-group col-12">
                                                                        <textarea className="form-control" rows="4" onChange={(e) => { setFeedback(e.target.value) }} placeholder="Please share your feedback about the product: Was the product as described? What is the quality like?"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="form-row my-2">
                                                                <div className="form-group col-12">
                                                                    <Dragger {...props}>
                                                                        <p className="ant-upload-drag-icon">
                                                                            <InboxOutlined />
                                                                        </p>
                                                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                                    </Dragger>
                                                                </div>
                                                            </div>

                                                            <br /><br />
                                                            <b>Important:</b>
                                                            <br />
                                                            <span>Maximum 6 images can be uploaded</span>
                                                            <ul>
                                                                <li>Image size can be maximum 5mb</li>
                                                                <li>It takes upto 24 hours for the image to be reviewed</li>
                                                                <li>Please ensure you meet the Community Guidelines before uploading your review</li>
                                                            </ul>
                                                        </div>

                                                        <div className="col-12 col-sm-5 col-xl-4 border-sm-left">
                                                            <span>Sold by <a className="text-info" href="">gemgolf.online</a></span>
                                                            <br />
                                                            <b>Rate and review your seller:</b>
                                                            <br />

                                                            <div className="row justify-content-center rate-seller">
                                                                <div className="col-auto face-block text-center">
                                                                    <label className="radio-inline">
                                                                        <input type="radio" name="emotion" id="sad" className="input-hidden" value="Negative" title="Negative" />
                                                                        <img src="https://img.icons8.com/color/100/000000/sad.png" width="50" height="50" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-auto face-block text-center">
                                                                    <label className="radio-inline">
                                                                        <input type="radio" name="emotion" id="meh" className="input-hidden" value="Neutral" title="Neutral" />
                                                                        <img src="https://img.icons8.com/color/100/000000/boring.png" width="50" height="50" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-auto face-block text-center">
                                                                    <label className="radio-inline">
                                                                        <input type="radio" name="emotion" id="happy" className="input-hidden" value="Positive" title="Positive" />
                                                                        <img src="https://img.icons8.com/color/100/000000/smiling.png " width="50" height="50" />
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <small><b id="result1" className="text-success"></b></small>
                                                            <br />
                                                            <small className="d-inline-block">Review detail</small>

                                                            <div>
                                                                <div className="form-row my-2">
                                                                    <div className="form-group col-12">
                                                                        <textarea className="form-control" rows="3"
                                                                            placeholder="How is your overall experience with the seller ?" onChange={(e) => { setOverall(e.target.value) }}></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br /> <br /> <br />

                                                            <b>Rate your Rider:</b>
                                                            <br />
                                                            <div className="row">
                                                                {/* <div className="col-12">
                                                                    <div className="rating1 rate-rider">
                                                                        <input type="radio" id="star15" name="rating-star2" value="Delightful" /><label for="star15" title="Delightful">Delightful</label>
                                                                        <input type="radio" id="star14" name="rating-star2" value="Satisfactory" /><label for="star14" title="Satisfactory">Satisfactory</label>
                                                                        <input type="radio" id="star13" name="rating-star2" value="Neutral" /><label for="star13" title="Neutral">Neutral</label>
                                                                        <input type="radio" id="star12" name="rating-star2" value="Poor" /><label for="star12" title="Poor">Poor</label>
                                                                        <input type="radio" id="star11" name="rating-star2" value="Very Poor" /><label for="star11" title="Very Poor">Very Poor</label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <small><b id="result2" className="text-success"></b></small>
                                                            <br />

                                                            <div>
                                                                <div className="form-row my-2">
                                                                    <div className="form-group col-12">
                                                                        <textarea className="form-control" rows="3" placeholder="How is your overall experience with the rider ?" onChange={(e) => { setRiderfeedback(e.target.value) }} ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <br /><br /><br />

                                                            <div className="set-content-inline">
                                                                <p className="pr-2">Review as {user.name} </p>
                                                                <label class="--switch">
                                                                    <input type="checkbox" />
                                                                    <span class="--slider">
                                                                        <i class="fas fa-check"></i>
                                                                        <i class="fas fa-times"></i>
                                                                    </span>
                                                                </label>
                                                                <p className="px-3 text-info"> Anonymous</p>
                                                            </div>

                                                            <div className="form-row mt-4">
                                                                <div className="form-group col-12">
                                                                    <button type="button" className="button-size-md btn button-lightblue w-100" onClick={handleSubmit} data-image={object.images[0].url} data-title={object.title}>SUBMIT</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )


                                            ) : (
                                                <>No Products to Review</>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Addreviews