import React, { useState, useEffect } from "react";

const Profilenav = () => {

    return (
        <>
        
            <div className="col-12 col-md-4 mb-3">
                <div class="vertical-nav" id="sidebar">
                        <p class="text-gray font-weight-bold text-uppercase px-3 small py-4 mb-0">
                            <a href="" class="text-success">Manage My Account</a>
                        </p>

                        <ul class="nav flex-column bg-white mb-0">
                            <li class="nav-item">
                                <a href="/profile" class="nav-link ">
                                    <i class="far fa-user mr-3 fa-fw"></i>
                                    My Profile
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="/addressbook" class="nav-link">
                                    <i class="far fa-address-book mr-3 fa-fw"></i>
                                    Address Book
                                </a>
                            </li>
                        </ul>

                        <p class="text-gray font-weight-bold text-uppercase px-3 small py-4 mb-0">
                            <a href="/myorders" class="text-success">My Orders</a>
                        </p>

                        <ul class="nav flex-column bg-white mb-0">
                            {/* <li class="nav-item">
                                <a href="/myreturns" class="nav-link">
                                    <i class="fas fa-undo-alt mr-3 fa-fw"></i>
                                    My Returns
                                </a>
                            </li> */}
                            <li class="nav-item">
                                <a href="/mycancellations" class="nav-link">
                                    <i class="fas fa-times mr-3 fa-fw"></i>
                                    My Cancellations
                                </a>
                            </li>
                        </ul>

                        <p class="text-gray font-weight-bold text-uppercase px-3 small py-4 mb-0">
                            <a href="/reviews" class="text-success">My Reviews</a>
                        </p>
                        <p class="text-gray font-weight-bold text-uppercase px-3 small py-4 mb-0">
                            <a href="/user/wishlist" class="text-success">My Wishlist</a>
                        </p>
                </div>
            </div>
        
        </>
    )
}

export default Profilenav