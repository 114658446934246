import React, { useState, useEffect } from "react";
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom'
const axios = require('axios');

const Cancellationresult = () => {
    const [order, setOrder] = useState('');
    const { user } = useSelector((state) => ({ ...state }));

    const urlParams = new URLSearchParams(window.location.search);

    let idorder = urlParams.get('oid');
    let pid = urlParams.get('pid');

    useEffect(() => {

        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_return.php?email=${user.email}&oid=${idorder}&pid=${pid}`)
                .then(function (response) {
                    // handle success
                    setOrder(response.data);

                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);


    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">

                        <Profilenav />
                        {/* <button id = "sidebarCollapse" type = "button" className = "btn btn-light bg-white rounded-pill shadow-sm mb-4"> <i className = "fa fa-bars"> </i></button> */}

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-3">
                                <div className="row mt-2 mb-4">
                                    <div className="col-12 col-sm-12 text-center">
                                        <i class="fas fa-check-circle fa-3x color-2"> </i>
                                        <h2 className="pt-3 result-msg"> Your cancellation was successful! </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="card card-2 p-3 mb-3">
                                <div className="card-header bg-white no-border text-center">
                                    <h4 className="my-3 text-center"> Canceled Items(s) </h4>
                                </div>
                                <div className="card-body">
                                    {order != '' ? (order.map((object, i) =>
                                        <div className="cancellation-items" >
                                            <div className="row mb-4 justify-content-center">
                                                <div className="col-12 col-sm-9 col-lg-5 col-xl-5">
                                                    <div className="set-inline img-c">
                                                        <img src={`http://admin.gemgolf.online/${object.image}`} className="img-fluid" />
                                                        <p> {object.title} </p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-9 col-lg-2 col-xl-2 text-right"> <span className="text-gray" > Qty: </span>
                                                    <span className="text-dark">1 </span>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center py-5">
                                                    <p className="py-4"> There are no data. </p>
                                                    <a className="btn btn-outline-success" href="/shop"> CONTINUE SHOPPING </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="card card-2 p-3 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h4 className="text-center">To be able to track your cancellation: </h4>
                                </div>
                                <div className="card-body">
                                    <div className="cancellation-result-section-footer">
                                        <div className="row mb-4">
                                            <div className="col-12 col-sm-12 text-center">
                                                <a href="/mycancellations" className="btn btn-danger"> Go to My Cancellation </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Cancellationresult