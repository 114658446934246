import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
const axios = require('axios');
const Addressbook = () => {

    const { user } = useSelector((state) => ({ ...state }));
    const [address, setAdderess] = useState('');

    useEffect(() => {
        if (user && user.email) {
            axios.get(`http://admin.gemgolf.online/ajax/my_adderess.php?email=${user.email}&status=cancelled`)
                .then(function (response) {
                    // handle success
                    setAdderess(response.data);

                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);



    return (
        <>

            <section className="page-content container mt-3">
                <div className="" id="content">
                    <div className="row">
                        <Profilenav />
                        {/* <button id="sidebarCollapse" type="button" className="btn btn-light bg-white rounded-pill shadow-sm mb-4"><i className="fa fa-bars"></i></button> */}

                        <div className="col-12 col-md-8">
                            <div className="card card-2 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">
                                        <span className="address-div">Address Book</span>
                                    </h3>
                                </div>
                                <div className="card-body">


                                    {/* <div className="row pb-5">
                                        <div className="col-12">
                                            <p>Save your shipping and billing address here.</p>
                                        </div>
                                    </div> */}

                                    {/* <div className="form-group col-12 text-right">
                                        <div className="btn-group" role="group" aria-label="">
                                            <button type="button" className="btn btn-tansparent text-info shipping-add-btn"><span className="d-none d-sm-inline-block">Make default </span>shipping address  |</button>
                                            <button type="button" className="btn btn-tansparent text-info billing-add-btn"><span className="d-none d-sm-inline-block">Make default </span>billing address</button>
                                        </div>
                                    </div> */}

                                    <form className="customized-form address-book ">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive">

                                                    <table className="table border">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Full Name</th>
                                                                <th scope="col">Address</th>
                                                                <th scope="col">Region</th>
                                                                <th scope="col">Phone Number</th>
                                                                <th scope="col"></th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>

                                                        {address != '' ? (address.map((object, i) =>

                                                            <>

                                                                <tbody>

                                                                    <tr>
                                                                        <td>{object.name}</td>
                                                                        <td><span className="badge badge-pill badge-info mr-2">HOME</span>{object.address}</td>
                                                                        <td> {object.province}-{object.district}</td>
                                                                        <td>
                                                                            {object.phone}
                                                                        </td>
                                                                        <td>
                                                                            <a href={`/addnewaddress?${object._id.$oid}`} className="text-info address-div">Edit</a>
                                                                            <div className="form-check shipping-div d-none">
                                                                                <input className="form-check-input" type="radio" name="shippingAddressRadio" id="" />
                                                                                <label className="form-check-label" for=""></label>
                                                                            </div>
                                                                            <div className="form-check billing-div d-none">
                                                                                <input className="form-check-input" type="radio" name="billingAddressRadio" id="" checked />
                                                                                <label className="form-check-label" for=""></label>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        )

                                                        ) : (
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <div className="text-center py-5">
                                                                        <p className="py-4">There are no Address yet.</p>
                                                                        <a className="btn btn-outline-success" href="/addnewaddress">Add New Address</a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5 save-btn d-none">
                                            <div className="col-12 text-right">
                                                <a href="" className="btn btn-info px-4">SAVE</a>
                                            </div>
                                        </div>

                                        <div className="row mt-3 mb-2 add-new-btn">
                                            <div className="col-12 text-right">
                                                <a href="/addnewaddress" className="btn btn-success px-4"><i className="fa fa-plus mr-2"></i>ADD NEW ADDRESS</a>
                                            </div>
                                        </div>

                                        {address != '' ? (address.map((object, i) =>
                                            <div className="row mb-5">
                                                <div className="col-6 col-lg-4 border-right">
                                                    <h5>DEFAULT SHIPPING ADDRESS</h5>
                                                    <b>{object.name}</b>
                                                    <p>{object.address}<br />
                                                        {object.phone}</p>
                                                </div>
                                                <div className="col-6 col-lg-4">
                                                    <h5>DEFAULT BILLING ADDRESS</h5>
                                                    <b>{object.name}</b>
                                                    <p>{object.address}<br />
                                                        {object.phone}</p>
                                                </div>
                                            </div>
                                        )) : ''}
                                    </form>


                                    {/* <tr>
                                                            <td>Mark</td>
                                                            <td><span className="badge badge-pill badge-info mr-2">HOME</span>Colombo, Sri Lanka</td>
                                                            <td>Western-Colombo</td>
                                                            <td>0123456789</td>
                                                            <td>
                                                                <p>Default Shipping Address</p>
                                                                <p>Default Billing Address</p>
                                                            </td>
                                                            <td>
                                                                <a href="" className="text-info address-div">Edit</a>
                                                                <div className="form-check shipping-div d-none">
                                                                    <input className="form-check-input" type="radio" name="shippingAddressRadio" id=""/>
                                                                    <label className="form-check-label" for=""></label>
                                                                </div>
                                                                <div className="form-check billing-div d-none">
                                                                    <input className="form-check-input" type="radio" name="billingAddressRadio" id="" checked />
                                                                    <label className="form-check-label" for=""></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Jacob</td>
                                                            <td><span className="badge badge-pill badge-danger mr-2">HOME</span>Mount Lavinia, Sri Lanka</td>
                                                            <td>Colombo</td>
                                                            <td>07123456789</td>
                                                            <td></td>
                                                            <td>
                                                                <a href="" className="text-info address-div">Edit</a>
                                                                <div className="form-check shipping-div d-none">
                                                                    <input className="form-check-input" type="radio" name="shippingAddressRadio" id="" checked />
                                                                    <label className="form-check-label" for=""></label>
                                                                </div>
                                                                <div className="form-check billing-div d-none">
                                                                    <input className="form-check-input" type="radio" name="billingAddressRadio" id=""/>
                                                                    <label className="form-check-label" for=""></label>
                                                                </div>
                                                            </td>
                                                        </tr> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Addressbook