import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
const axios = require('axios');

const Mycancellations = () => {
    const { user } = useSelector((state) => ({ ...state }));
    const [order, setOrder] = useState('');

    useEffect(() => {
        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_ordes.php?email=${user.email}&status=cancelled`)
                .then(function (response) {
                    // handle success
                    setOrder(response.data);

                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);


    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">

                        <Profilenav />
                        {/* <button id="sidebarCollapse" type="button" className="btn btn-light bg-white rounded-pill shadow-sm mb-4"><i className="fa fa-bars"></i></button> */}

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">My Cancellations</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group my-3 row">
                                        <label for="" className="col-sm-2 col-md-3 col-xl-2 col-form-label">Show :</label>
                                        <div className="col-sm-10 col-md-6 col-xl-4">
                                            <select className="custom-select">
                                                <option selected disabled>Select</option>
                                                <option value="1">Last 5 orders</option>
                                                <option value="2">Last 15 days</option>
                                                <option value="3">Last 30 days</option>
                                                <option value="4">Last 6 months</option>
                                                <option value="5">Orders placed in 2021</option>
                                                <option value="6">All Orders</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group my-3">
                                    {order != '' ? (order.map((object, i) =>
                                        <>

                                            <form className="customized-form">
                                                <div className="card no-border my-3">
                                                    <div className="card-header bg-white">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                                <h5 className="mb-0">Order <a className="text-info" href="#">#{object._id.$oid}</a></h5>
                                                                <small>Placed on {object.date}</small>
                                                            </div>
                                                            <div className="col-12 col-sm-4 col-md-6 col-lg-4 text-right">
                                                                <h5 className="mb-0"><a className="text-info" href="#">MANAGE</a></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {object.cart.map((cartData, j) =>

                                                            <div className="row mb-4">
                                                                <div className="col-12 col-sm-3 col-lg-3 col-xl-2">
                                                                    <img src={`http://admin.gemgolf.online/${cartData.images[0].url}`} className="img-fluid" />
                                                                </div>
                                                                <div className="col-12 col-sm-9 col-lg-3 col-xl-4">
                                                                    <p className="mt-2 mt-sm-0 product-card-title">{cartData.title}</p>
                                                                </div>
                                                                <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                    <span className="text-gray">Qty: </span><span className="text-dark">{cartData.count}</span>
                                                                </div>
                                                                <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2 mt-2 mt-lg-0">
                                                                    <span className="badge badge-pill badge-success mr-2">{object.status == '' ? 'processing' : object.status}</span>
                                                                </div>
                                                                <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                    <p>{object.status === null ? 'processing' : object.status} on {object.date}</p>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    )

                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center py-5">
                                                    <p className="py-4">There are no orders placed yet.</p>
                                                    <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Mycancellations