import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import './Collection.css';
import FooterItem from '../components/FooterItem';

const Collection = () => {

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCollection().then((c) => {
      setCategories(c.data);
      setLoading(false);
    });
  }, []);

  return (
    <>

      <section class="collection mb-5">
        <div class="container">

          <div class="row">
            <div class="col-12 text-center">
              <ul class="collection-list row">

                {categories.map((c) => (
                  <li class="collection-card col-sm-6 col-md-4" key={c.id}>
                    <a href={`/shop?cat=${c.name}`} class="collection-card-link">
                      <div class="collection-card-title">{c.name}</div>
                      <div class="collection-card-subtitle">{c.active_count} products</div>
                      <div class="collection-items">

                        {ReactHtmlParser(c.image != '' ? `${c.image}` : `<div class="collection-item"><div class="image-wrapper"><img src="./default.png" class="img-fluid"/></div></div>`)}

                      </div>
                    </a>
                  </li>
                ))}

              </ul>
            </div>
          </div>
        </div>
      </section>


      <FooterItem />
    </>
  )
}

export default Collection