import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
const axios = require('axios');

const Orderdetails = () => {
    const [order, setOrder] = useState('');
    const { user } = useSelector((state) => ({ ...state }));

    const urlParams = new URLSearchParams(window.location.search);

    let idorder = urlParams.get('oid');

    useEffect(() => {

        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_order.php?email=${user.email} & oid=${idorder}`)
                .then(function (response) {
                    if (response.data && response.data.length > 0) {
                        setOrder(response.data);
                    }
                    console.log('user');
                    console.log(user);
                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);


    let thousandsSeparator = (num) => {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }


    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">

                        <Profilenav />
                        {/* <button id="sidebarCollapse" type="button" className="btn btn-light bg-white rounded-pill shadow-sm mb-4"><i className="fa fa-bars"></i></button> */}

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-5">

                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">Order Details</h3>
                                </div>
                                <div className="card-body">

                                    <form className="customized-form">

                                        {order != '' ? (order.map((object, i) =>
                                            <>

                                                <div className="card my-3">
                                                    <div className="card-header bg-white">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                                <h5 className="mb-0">Order #{object._id.$oid}</h5>
                                                                <small>Placed on {object.createdAt}</small>
                                                            </div>
                                                            <div className="col-12 col-sm-4 col-md-6 col-lg-4 text-right">
                                                                <h3 className="mb-0">Total: <a className="text-secondary" href="">Rs. {thousandsSeparator(parseFloat(object.total))}</a></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {object.cart.map((cartData, j) =>
                                                    <>
                                                        <div className="card my-3">
                                                            <div className="card-header bg-white">
                                                                <div className="row">
                                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <h5 className="mb-0"><i className="fas fa-gift mr-3"></i>Package {j + 1} </h5>
                                                                        <small>Sold by <a className="text-info" href="">gemgolf.online </a></small>
                                                                    </div>
                                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                                                        <a href={`/requestcancellation?oid=${object._id.$oid}&pid=${cartData._id}`} class="btn btn-danger mt-1">Cancel</a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row mb-4">
                                                                    <div className="col-12 col-sm-3 col-lg-2 col-xl-2">
                                                                        <img src={`http://admin.gemgolf.online/${cartData.images[0].url}`} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-9 col-lg-2 col-xl-3">
                                                                        <p className="mt-2 mt-sm-0 product-card-title">{cartData.title}</p>

                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <p>Rs. {thousandsSeparator(cartData.price)}</p>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <span className="text-gray">Qty: </span><span className="text-dark">{cartData.count}</span>
                                                                    </div>
                                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2 mt-2 mt-lg-0">
                                                                        {/* <span className="badge badge-pill badge-light mr-2">{{cartData.sta}} <br/> in Progress</span> */}
                                                                    </div>
                                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-1">
                                                                        <i className="fas fa-gifts fa-2x text-gray" data-toggle="modal" data-target=".progress-modal"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="card my-3">
                                                            <div className="card-body">
                                                                <div className="row mb-4">
                                                                    <div className="col-12">
                                                                        <h4>Shipping Address</h4>
                                                                        <span>{object.name}</span> <br />
                                                                        <span><span className="badge badge-pill badge-danger mr-2"> HOME </span>
                                                                            {object.address}</span> <br />
                                                                        <span>{object.phone}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="card my-3">
                                                            <div className="card-body">
                                                                <div className="row mb-4">
                                                                    <div className="col-12">
                                                                        <h4>Billing Address</h4>
                                                                        <span>{object.name}</span> <br />
                                                                        <span><span className="badge badge-pill badge-danger mr-2"> HOME </span>
                                                                            {object.address}</span> <br />
                                                                        <span>{object.phone}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="card my-3">
                                                            <div className="card-body">
                                                                <div className="row mb-4">
                                                                    <div className="col-6">
                                                                        <h4>Total Summary</h4>
                                                                        <span>Subtotal</span> <br />
                                                                        <span>Shipping Fee</span>
                                                                    </div>
                                                                    <div className="col-6 text-right">
                                                                        <br /><br />
                                                                        <span>Rs. {thousandsSeparator(object.total - object.shipping)}</span> <br />
                                                                        <span>Rs. {thousandsSeparator(object.shipping)}</span>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row mb-4">
                                                                    <div className="col-6">
                                                                        <span>Total</span> <br />
                                                                        <span>Paid by </span>
                                                                    </div>
                                                                    <div className="col-6 text-right">
                                                                        <h4>Rs. {thousandsSeparator(parseFloat(object.total))}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )

                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <p className="py-4">There are no orders placed yet.</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING </a>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Orderdetails