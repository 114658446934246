import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';

const Myreturns = () => {
    return (
        <>
            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">

                        <Profilenav />
                        {/* <button id="sidebarCollapse" type="button" className="btn btn-light bg-white rounded-pill shadow-sm mb-4"><i className="fa fa-bars"></i></button> */}

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">My Returns</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="text-center py-5">
                                                <p className="py-4">There are no returns yet.</p>
                                                <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING</a>
                                            </div>
                                        </div>
                                    </div>

                                    <form className="customized-form">
                                        <div className="card my-3">
                                            <div className="card-header bg-white">
                                                <div className="row">
                                                    <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                        <p className="mb-0">Requested on 10 Aug 2021</p>
                                                        <small>Order <a className="text-info" href="profile_order_details.php">#204470513477147 </a></small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-12 col-sm-3 col-lg-2 col-xl-2">
                                                        <img src="images/sample_product.webp" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12 col-sm-9 col-lg-5 col-xl-5">
                                                        <p className="mt-2 mt-sm-0 product-card-title">Smart 20 LED Solar Lights Outdoor, Waterproof Solar Motion Sensor Light</p>
                                                    </div>
                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                        <span className="text-gray">Qty: </span><span className="text-dark">1</span>
                                                    </div>
                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-3 col-xl-3 mt-2 mt-lg-0">
                                                        <span className="badge badge-pill badge-light mr-2">Returned</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col-12 col-sm-3 col-lg-2 col-xl-2">
                                                        <img src="images/sample_product.webp" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12 col-sm-9 col-lg-5 col-xl-5">
                                                        <p className="mt-2 mt-sm-0 product-card-title">Smart 20 LED Solar Lights Outdoor, Waterproof Solar Motion Sensor Light</p>
                                                    </div>
                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                        <span className="text-gray">Qty: </span><span className="text-dark">1</span>
                                                    </div>
                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-3 col-xl-3 mt-2 mt-lg-0">
                                                        <span className="badge badge-pill badge-light mr-2">Returned</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card my-3">
                                            <div className="card-header bg-white">
                                                <div className="row">
                                                    <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                        <p className="mb-0">Requested on 10 Aug 2021</p>
                                                        <small>Order <a className="text-info" href="profile_order_details.php">#204470513477147 </a></small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-12 col-sm-3 col-lg-2 col-xl-2">
                                                        <img src="images/sample_product.webp" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12 col-sm-9 col-lg-5 col-xl-5">
                                                        <p className="mt-2 mt-sm-0 product-card-title">Smart 20 LED Solar Lights Outdoor, Waterproof Solar Motion Sensor Light</p>
                                                    </div>
                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                        <span className="text-gray">Qty: </span><span className="text-dark">1</span>
                                                    </div>
                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-3 col-xl-3 mt-2 mt-lg-0">
                                                        <span className="badge badge-pill badge-light mr-2">Returned</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Myreturns 