import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import Profilenav from '../pages/Profilenav';
import $ from 'jquery';

const Mywishlist = () => {

    $('.tabs-nav a').on('click', function (event) {
        event.preventDefault();

        $('.tab-active').removeClass('tab-active');
        $(this).parent().addClass('tab-active');
        $('.tabs-stage .tab-se').hide();
        $($(this).attr('href')).show();
    });
    $('.tabs-nav a:first').trigger('click');

    return (
        <>

            <section className="page-content mt-3 container">
                <div className="" id="content">
                    <div className="row">
                        <Profilenav />

                        <div className="col-12 col-md-8">
                            <div className="card card-2 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">My Wishlist</h3>
                                </div>
                                <ul className="tabs-nav">
                                    <li className="tab-active"><a href="#tab-1" rel="nofollow">My Wishlist</a>
                                    </li>
                                    <li className=""><a href="#tab-2" rel="nofollow">Past Purchase</a>
                                    </li>
                                </ul>
                                <div className="tabs-stage">
                                    <div className="tab-se" id="tab-1" style={{ display: "block" }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center py-5">
                                                    <i className="far fa-sad-cry fa-2x text-gray"></i>
                                                    <p className="py-4">
                                                        There are no favorites yet. <br />
                                                        Add your favorites to wishlist and they will show here.
                                                    </p>
                                                    <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card my-3">
                                            <div className="card-header bg-white">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <a href="" className="text-info" data-toggle="modal" data-target=".addto_cart_modal">ADD ALL TO CART</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card my-3">
                                            <div className="card-header bg-white">
                                                <div className="row">
                                                    <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                        <h6 className="mb-0">Watchlist </h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-12">
                                                        <a href="" className="text-gray">R&R HOME NEEDS</a>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-12 col-sm-3 col-lg-2">
                                                        <img src="images/sample_product.webp" className="img-fluid small-img" />
                                                    </div>
                                                    <div className="col-12 col-sm-9 col-lg-5">
                                                        <p className="mt-2 mt-sm-0 product-card-title">Smart 20 LED Solar Lights Outdoor, Waterproof Solar Motion Sensor Light</p>
                                                        <small className="text-gray">Color Family:Green</small> <br />
                                                        <button type="button" className="btn-tansparent gray-icon mt-2" data-toggle="modal" data-target=".delete-wishlist">
                                                            <i className="far fa-trash-alt text-gray"></i>
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-3">
                                                        <h5 className="price">Rs.224</h5>
                                                        <div className="card-price">
                                                            <span className="origin-price">Rs.978</span>
                                                            <span className="discount">-77%</span>
                                                        </div>
                                                        <span className="text-success">Price dropped</span>
                                                    </div>
                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2">
                                                        <button className="btn btn-danger" data-toggle="modal" data-target=".addto_cart_modal">
                                                            <i className="fa fa-cart-plus fa-1x mx-2"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col-12">
                                                        <a href="" className="text-gray">GET PAY</a>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-12 col-sm-3 col-lg-2">
                                                        <img src="images/sample_product.webp" className="img-fluid small-img" />
                                                    </div>
                                                    <div className="col-12 col-sm-9 col-lg-5">
                                                        <p className="mt-2 mt-sm-0 product-card-title">Smart 20 LED Solar Lights Outdoor, Waterproof Solar Motion Sensor Light</p>
                                                        <small className="text-gray">Color Family:Green</small> <br />
                                                        <button type="button" className="btn-tansparent gray-icon mt-2" data-toggle="modal" data-target=".delete-wishlist">
                                                            <i className="far fa-trash-alt text-gray"></i>
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-3">
                                                        <h5 className="price">Rs.224</h5>
                                                        <div className="card-price">
                                                            <span className="origin-price">Rs.978</span>
                                                            <span className="discount">-77%</span>
                                                        </div>
                                                        <span className="text-success">Price dropped</span>
                                                    </div>
                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2">
                                                        <button className="btn btn-danger" data-toggle="modal" data-target=".addto_cart_modal">
                                                            <i className="fa fa-cart-plus fa-1x mx-2"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-se" id="tab-2" style={{ display: "none" }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center py-5">
                                                    <i className="far fa-sad-tear fa-2x text-gray"></i>
                                                    <p className="py-4">
                                                        There are no favorites yet. <br />
                                                        Add your favorites to wishlist and they will show here.
                                                    </p>
                                                    <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <FooterItem />
        </>
    )
}

export default Mywishlist