import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';

const Ordercomplete = () => {

    return (
        <>

            <section id="thank-you" className="thank-you-section">
                <div className="container">
                    <div className="col-12 col-md-8 offset-md-2">
                        <div className="thank-you-wrapper position-relative thank-wrapper-style-one">
                            <div className="thank-txt text-center">
                                <div className="thank-icon">
                                    <img src="images/mark.png" alt="" />
                                </div>
                                <h5 className="txt-1">Hey {localStorage.getItem("name")}</h5>
                                <h1>Your Order is Confirmed!</h1>
                                <p>We'll send you a shipping confirmation email as soon as your order ships.<br />
                                </p>
                                <a className="d-block text-center text-uppercase" href="/" >back to home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Ordercomplete