import React, { useState, useEffect } from "react";
import { getCollection } from "../functions/product";
import ReactHtmlParser from 'react-html-parser';
import FooterItem from '../components/FooterItem';
import $ from 'jquery';
import Profilenav from '../pages/Profilenav';
import { useSelector, useDispatch } from "react-redux";
const axios = require('axios');

const Myorders = () => {
    const { user } = useSelector((state) => ({ ...state }));

    $('.tabs-nav a').on('click', function (event) {
        event.preventDefault();

        $('.tab-active').removeClass('tab-active');
        $(this).parent().addClass('tab-active');
        $('.tabs-stage .tab-se').hide();
        $($(this).attr('href')).show();
    });

    $('.tabs-nav a:first').trigger('click');
    const [order, setOrder] = useState([]);
    const [orderall, setOrderall] = useState('');
    const [ordertoship, setOrdertoship] = useState('');
    const [ordertorecevie, setOrdertorecevie] = useState('');
    const [ordertopay, setOrdertopay] = useState('');

    let validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    useEffect(() => {
        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_ordes.php?email=${user.email}&status=null`)
                .then(function (response) {


                    if (response.data && response.data.length > 0) {
                        setOrdertopay(response.data);
                    }
                    // handle success
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);


    useEffect(() => {
        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_ordes.php?email=${user.email}`)
                .then(function (response) {
                    // handle success

                    if (response.data && response.data.length > 0) {
                        setOrder(response.data);
                    }

                    console.log('user');
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }

        if (user && user.email) {
            $.ajax({
                url: `https://admin.onme.lk/ajax/my_ordes.php?email=${user.email}`,
                type: "post",
                data: {},
                success: function (response) {
                    if (response.data && response.data.length > 0) {
                        setOrder(response);
                    }
                    // You will get response from your PHP page (what you echo or print)
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log(textStatus, errorThrown);
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_ordes.php?email=${user.email}&status=readytoship`)
                .then(function (response) {
                    // handle success

                    if (response.data && response.data.length > 0) {
                        setOrdertoship(response.data);
                    }
                    //console.log(user.email);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);

    useEffect(() => {

        if (user && user.email) {
            axios.get(`https://admin.onme.lk/ajax/my_ordes.php?email=${user.email}&status=shipped`)
                .then(function (response) {
                    // handle success
                    if (response.data && response.data.length > 0) {
                        setOrdertorecevie(response.data);
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
    }, [user]);


    return (
        <>

            <section className="page-content container mt-3">
                <div className="" id="content">
                    <div className="row">
                        <Profilenav />

                        <div className="col-12 col-md-8">
                            <div className="card card-2 p-3 mb-5">
                                <div className="card-header bg-white no-border text-center">
                                    <h3 className="my-3">My Orders</h3>
                                </div>

                                <ul className="tabs-nav tabs-nav-4">
                                    <li className="tab-active"><a href="#tab-1" rel="nofollow">All</a></li>
                                    <li className=""><a href="#tab-2" rel="nofollow">To Pay</a></li>
                                    <li className=""><a href="#tab-3" rel="nofollow">To Ship</a></li>
                                    <li className=""><a href="#tab-4" rel="nofollow">To Receive</a></li>
                                </ul>

                                <div className="tabs-stage">
                                    <div className="tab-se" id="tab-1" style={{ display: "block" }}>

                                        {order != '' && order.length > 0 ? (order.map((object, i) =>
                                            <>

                                                <form className="customized-form">
                                                    <div className="card no-border my-3">
                                                        <div className="card-header bg-white">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                                    <h5 className="mb-0">Order <a className="text-info" href="#">#{object._id.$oid}</a></h5>
                                                                    <small>Placed on {object.date}</small>
                                                                </div>
                                                                <div className="col-12 col-sm-4 col-md-6 col-lg-4 text-right">
                                                                    <h5 className="mb-0"><a className="text-info" href={`/orderdetails?oid=${object._id.$oid}`}>MANAGE</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">

                                                            {object.cart && object.cart.length > 0 && object.cart.map((cartData, j) =>
                                                                <div className="row mb-4">
                                                                    <div className="col-12 col-sm-3 col-lg-3 col-xl-2">
                                                                        <img src={cartData.images && (cartData.images.length > 1) ? (validURL(cartData.images[1].url) ? cartData.images[1].url : 'http://admin.gemgolf.online/' + cartData.images[1].url) : (validURL(cartData.images[0].url) ? cartData.images[0].url : 'http://admin.gemgolf.online/' + cartData.images[0].url)}
                                                                            className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-9 col-lg-3 col-xl-4">
                                                                        <p className="mt-2 mt-sm-0 product-card-title">{cartData.title}</p>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <span className="text-gray">Qty: </span><span className="text-dark">{cartData.count}</span>
                                                                    </div>
                                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2 mt-2 mt-lg-0">
                                                                        <span className="badge badge-pill badge-success mr-2">

                                                                            {((object.status === null) || (object.status === 'readytoship') || (object.status === 'readytocollect')) ? 'Processing'
                                                                                : ((object.status === 'shipped') ? 'Shipped' : '')
                                                                            }
                                                                            {object.status === 'cancelled' ? 'Cancelled ' : ''}
                                                                            {object.status === 'delivered' ? 'Delivered ' : ''}

                                                                        </span>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        {/* {((object.status===null) || (object.status==='readytoship') ||(object.status==='readytocollect')) ?'Processing '
                                                                            :  ((object.status==='shipped')?'Shipped ':'')
                                                                            } 
                                                                            {object.status==='cancelled'?'Cancelled ':' '}
                                                                            {object.status==='delivered'?'Delivered ':' '} 
                                                                            on {object.date}</p> */}
                                                                        <p>{object.date}</p>
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </form>

                                            </>
                                        )


                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <p className="py-4">There are no orders placed yet.</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="tab-se" id="tab-2" style={{ display: "none" }}>
                                        <div className="form-group my-3 row">
                                            <label for="" className="col-sm-2 col-md-3 col-xl-2 col-form-label">Show :</label>
                                            <div className="col-sm-10 col-md-6 col-xl-4">
                                                <select className="custom-select">
                                                    <option selected disabled>Select</option>
                                                    <option value="1">Last 5 orders</option>
                                                    <option value="2">Last 15 days</option>
                                                    <option value="3">Last 30 days</option>
                                                    <option value="4">Last 6 months</option>
                                                    <option value="5">Orders placed in 2021</option>
                                                    <option value="6">All Orders</option>
                                                </select>
                                            </div>
                                        </div>

                                        {order !== '' ? (order.map((object, i) =>
                                            <>
                                                <form className="customized-form">
                                                    <div className="card no-border my-3">
                                                        <div className="card-header bg-white">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                                    <h5 className="mb-0">Order <a className="text-info" href="#">#{object._id.$oid}</a></h5>
                                                                    <small>Placed on {object.date}</small>
                                                                </div>
                                                                <div className="col-12 col-sm-4 col-md-6 col-lg-4 text-right">
                                                                    <h5 className="mb-0"><a className="text-info" href={`/orderdetails?oid=${object._id.$oid}`}>MANAGE</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            {object.cart && object.cart.length > 0 && object.cart.map((cartData, j) =>
                                                                <div className="row mb-4">
                                                                    <div className="col-12 col-sm-3 col-lg-3 col-xl-2">
                                                                        <img src={cartData.images && (cartData.images.length > 1) ? (validURL(cartData.images[1].url) ? cartData.images[1].url : 'http://admin.gemgolf.online/' + cartData.images[1].url) : (validURL(cartData.images[0].url) ? cartData.images[0].url : 'http://admin.gemgolf.online/' + cartData.images[0].url)} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-9 col-lg-3 col-xl-4">
                                                                        <p className="mt-2 mt-sm-0 product-card-title">{cartData.title}</p>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <span className="text-gray">Qty: </span><span className="text-dark">{cartData.count}</span>
                                                                    </div>
                                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2 mt-2 mt-lg-0">
                                                                        <span className="badge badge-pill badge-success mr-2">{((object.status === null) || (object.status === 'readytoship') || (object.status === 'readytocollect')) ? 'Processing'
                                                                            : ((object.status === 'shipped') ? 'Shipped' : '')
                                                                        }</span>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <p>{object.date}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        )

                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <p className="py-4">There are no orders placed yet</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING 1</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* } */}
                                    </div>

                                    <div className="tab-se" id="tab-3" style={{ display: "none" }}>
                                        <div className="form-group my-3 row">
                                            <label for="" className="col-sm-2 col-md-3 col-xl-2 col-form-label">Show :</label>
                                            <div className="col-sm-10 col-md-6 col-xl-4">
                                                <select className="custom-select">
                                                    <option selected disabled>Select</option>
                                                    <option value="1">Last 5 orders</option>
                                                    <option value="2">Last 15 days</option>
                                                    <option value="3">Last 30 days</option>
                                                    <option value="4">Last 6 months</option>
                                                    <option value="5">Orders placed in 2021</option>
                                                    <option value="6">All Orders</option>
                                                </select>
                                            </div>
                                        </div>

                                        {ordertoship != '' ? (ordertoship.map((object, i) =>
                                            <>
                                                <form className="customized-form">
                                                    <div className="card no-border my-3">
                                                        <div className="card-header bg-white">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                                    <h5 className="mb-0">Order <a className="text-info" href="#">#{object._id.$oid}</a></h5>
                                                                    <small>Placed on {object.date}</small>
                                                                </div>
                                                                <div className="col-12 col-sm-4 col-md-6 col-lg-4 text-right">
                                                                    <h5 className="mb-0"><a className="text-info" href={`/orderdetails?oid=${object._id.$oid}`}>MANAGE</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">

                                                            {object.cart && object.cart.length > 0 && object.cart.map((cartData, j) =>

                                                                <div className="row mb-4">
                                                                    <div className="col-12 col-sm-3 col-lg-3 col-xl-2">
                                                                        <img src={cartData.images && (cartData.images.length > 1) ? (validURL(cartData.images[1].url) ? cartData.images[1].url : 'http://admin.gemgolf.online/' + cartData.images[1].url) : (validURL(cartData.images[0].url) ? cartData.images[0].url : 'http://admin.gemgolf.online/' + cartData.images[0].url)} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-9 col-lg-3 col-xl-4">
                                                                        <p className="mt-2 mt-sm-0 product-card-title">{cartData.title}</p>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <span className="text-gray">Qty: </span><span className="text-dark">{cartData.count}</span>
                                                                    </div>
                                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2 mt-2 mt-lg-0">
                                                                        <span className="badge badge-pill badge-success mr-2">{((object.status === null) || (object.status === 'readytoship') || (object.status === 'readytocollect')) ? 'Processing'
                                                                            : ((object.status === 'shipped') ? 'Shipped' : '')
                                                                        }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <p>{object.date}</p>
                                                                    </div>
                                                                </div>

                                                            )}

                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        )


                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <p className="py-4">There are no orders placed yet</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING 1</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className="tab-se" id="tab-4" style={{ display: "none" }}>
                                        <div className="form-group my-3 row">
                                            <label for="" className="col-sm-2 col-md-3 col-xl-2 col-form-label">Show :</label>
                                            <div className="col-sm-10 col-md-6 col-xl-4">
                                                <select className="custom-select">
                                                    <option selected disabled>Select</option>
                                                    <option value="1">Last 5 orders</option>
                                                    <option value="2">Last 15 days</option>
                                                    <option value="3">Last 30 days</option>
                                                    <option value="4">Last 6 months</option>
                                                    <option value="5">Orders placed in 2021</option>
                                                    <option value="6">All Orders</option>
                                                </select>
                                            </div>
                                        </div>

                                        {ordertorecevie != '' ? (ordertorecevie.map((object, i) =>
                                            <>
                                                <form className="customized-form">

                                                    <div className="card no-border my-3">
                                                        <div className="card-header bg-white">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-8 col-md-6 col-lg-8">
                                                                    <h5 className="mb-0">Order <a className="text-info" href="#">#{object._id.$oid}</a></h5>
                                                                    <small>Placed on {object.date}</small>
                                                                </div>
                                                                <div className="col-12 col-sm-4 col-md-6 col-lg-4 text-right">
                                                                    <h5 className="mb-0"><a className="text-info" href={`/orderdetails?oid=${object._id.$oid}`}>MANAGE</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="card-body">

                                                            {object.cart && object.cart.length > 0 && object.cart.map((cartData, j) =>
                                                                <div className="row mb-4">
                                                                    <div className="col-12 col-sm-3 col-lg-3 col-xl-2">
                                                                        <img src={cartData.images && (cartData.images.length > 1) ? (validURL(cartData.images[1].url) ? cartData.images[1].url : 'http://admin.gemgolf.online/' + cartData.images[1].url) : (validURL(cartData.images[0].url) ? cartData.images[0].url : 'http://admin.gemgolf.online/' + cartData.images[0].url)} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-9 col-lg-3 col-xl-4">
                                                                        <p className="mt-2 mt-sm-0 product-card-title">{cartData.title}</p>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <span className="text-gray">Qty: </span><span className="text-dark">{cartData.count}</span>
                                                                    </div>
                                                                    <div className="col-6 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2 mt-2 mt-lg-0">
                                                                        <span className="badge badge-pill badge-success mr-2">

                                                                            {((object.status === null) || (object.status === 'readytoship') || (object.status === 'readytocollect')) ? 'Processing'
                                                                                : ((object.status === 'shipped') ? 'Shipped' : '')
                                                                            }

                                                                        </span>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 offset-sm-3 offset-lg-0 col-lg-2 col-xl-2">
                                                                        <p>{object.date}</p>
                                                                    </div>
                                                                </div>

                                                            )}

                                                        </div>
                                                    </div>
                                                </form>

                                            </>
                                        )

                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-center py-5">
                                                        <p className="py-4">There are no orders placed yet</p>
                                                        <a className="btn btn-outline-success" href="/shop">CONTINUE SHOPPING 1</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterItem />
        </>
    )
}

export default Myorders